import {
  AuthFormValues,
  ForgotPasswordValues,
  ResendPayload,
  VerifyValues
} from 'src/models'
import { baseApi } from 'src/services/base'

const prefix = '/auth'

export const apiLogin = (payload: AuthFormValues) => {
  return baseApi.post(`${prefix}/login`, payload)
}

export const apiRegister = (payload: AuthFormValues) => {
  return baseApi.post(`${prefix}/register`, payload)
}

export const apiRegisterVerify = (payload: VerifyValues) => {
  return baseApi.post(`${prefix}/register/verify`, payload)
}

export const apiRegisterResend = (payload: ResendPayload) => {
  return baseApi.post(`${prefix}/register/resend`, payload)
}

export const apiForgotPassword = (payload: ForgotPasswordValues) => {
  return baseApi.post(`${prefix}/forgot-password`, payload)
}

export const apiForgotPasswordResend = (payload: ResendPayload) => {
  return baseApi.post(`${prefix}/forgot-password/resend`, payload)
}

export const apiResetPassword = (payload: VerifyValues) => {
  return baseApi.post(`${prefix}/reset-password`, payload)
}

export const apiRefreshToken = () => {
  return baseApi.post(`${prefix}/refresh`)
}

export const apiLogout = ({ device_id }: { device_id: string }) => {
  return baseApi.post(`${prefix}/logout`, { device_id })
}
