// uP40
import { Form, Formik } from 'formik'
import FormItems from 'src/components/FormItems'
import LayoutMain from 'src/layouts/LayoutMain'
import textJP from 'src/langs/ja'
import { useCallback, useEffect, useState } from 'react'
import ErrorResponse from 'src/components/ErrorResponse'
import { Button } from '@mui/material'
import { vehicleCreateInitialValues } from 'src/helpers/initialValues'
import { VehicleCreateValues } from 'src/models'
import { vehicleCreateValidationSchema } from 'src/helpers/schema'
import {
  alphanumericHalfWidth,
  hiraganaAndAlphabetRegex,
  hiraganaAndKanjiRegex,
  onlyNumberRegex
} from 'src/constants'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { clearBlock, setBlock } from 'src/stores/blockUI'
import {
  apiGetVehicleInfo,
  apiVehicleUpdate,
  apiVehicleDelete
} from 'src/services/UserService'
import path from 'src/routers/Path'
import ConfirmDialog from 'src/components/dialog/ConfirmDialog'
import { RESPONSE_STATUS } from 'src/constants/enum'
import RenderIf from 'src/components/RenderIf'
import { handleNavigateApp, hasAnyValueObject } from 'src/utils'

const VehicleCreate = () => {
  const [open, setOpen] = useState<boolean>(false)
  const [errorMess, setErrorMess] = useState<string>('')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [vehicleInfo, setVehicleInfo] = useState(vehicleCreateInitialValues)

  const getVehicleInfo = useCallback(async () => {
    const res = await apiGetVehicleInfo()
    if (res?.status === RESPONSE_STATUS.SUCCESS) {
      setVehicleInfo(prev => res?.data?.data || prev)
    }
  }, [])

  useEffect(() => {
    getVehicleInfo()
  }, [getVehicleInfo])

  const submit = async (values: VehicleCreateValues) => {
    try {
      setErrorMess('')
      dispatch(setBlock())
      const repsonse = await apiVehicleUpdate(values)
      if (repsonse?.status === RESPONSE_STATUS.SUCCESS) {
        handleNavigateApp({
          pathMobile: path.close_web,
          pathBrowser: path.home,
          navigate
        })
      }
    } catch (error: any) {
      const message = error?.response?.data?.message
      setErrorMess(message)
    } finally {
      dispatch(clearBlock())
    }
  }

  const confirmDelete = async () => {
    try {
      setErrorMess('')
      dispatch(setBlock())
      const res = await apiVehicleDelete()
      if (res?.status === RESPONSE_STATUS.SUCCESS) {
        handleNavigateApp({
          pathMobile: path.close_web,
          pathBrowser: path.home,
          navigate
        })
      }
    } finally {
      dispatch(clearBlock())
    }
  }

  return (
    <LayoutMain
      title={textJP.vehicle_info.title}
      wrapperClassName='ly_container'
    >
      <Formik
        enableReinitialize
        initialValues={vehicleInfo}
        validationSchema={vehicleCreateValidationSchema}
        onSubmit={submit}
      >
        {() => {
          return (
            <Form>
              <dl className='bl_form ut_mt14'>
                <FormItems
                  name='place_name'
                  label={textJP.common.place_name}
                  description={textJP.vehicle_info.place_name_des}
                  regex={hiraganaAndKanjiRegex}
                  maxLength={5}
                />

                <FormItems
                  name='classification_number'
                  label={textJP.common.classification_number}
                  description={textJP.vehicle_info.classification_number_des}
                  regex={alphanumericHalfWidth}
                  maxLength={3}
                />

                <FormItems
                  name='car_number_hiragana'
                  label={textJP.common.car_number_hiragana}
                  regex={hiraganaAndAlphabetRegex}
                  maxLength={1}
                />

                <FormItems
                  name='serial_number'
                  inputMode='numeric'
                  regex={onlyNumberRegex}
                  label={textJP.common.serial_number}
                  description={textJP.vehicle_info.serial_number_des}
                  maxLength={4}
                />

                <div className='el_txt_center ut_mt20'>
                  <ErrorResponse errorMess={errorMess} />
                  <Button
                    type='submit'
                    variant='contained'
                    className='el_btn_common'
                  >
                    {textJP.btn.save}
                  </Button>
                </div>

                <RenderIf isTrue={hasAnyValueObject(vehicleInfo)}>
                  <div className='el_txt_center ut_mt20'>
                    <button
                      className='el_txt_link'
                      type='button'
                      onClick={() => setOpen(true)}
                    >
                      {textJP.vehicle_info.delete_message}
                    </button>
                  </div>
                </RenderIf>

                <ConfirmDialog
                  title={textJP.vehicle_info.modal_title}
                  open={open}
                  setOpen={setOpen}
                  cancelClassName='bl_dialog_btn__black'
                  confirmClassName='bl_dialog_btn__red'
                  cancelLabel={textJP.btn.back}
                  confirmLabel={textJP.btn.delete}
                  onConfirm={confirmDelete}
                />
              </dl>
            </Form>
          )
        }}
      </Formik>
    </LayoutMain>
  )
}

export default VehicleCreate
