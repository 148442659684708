import { baseApi } from 'src/services/base'
import {
  ChangeEmailValues,
  ChangePasswordValues,
  DeviceTokenValues,
  ProfileValues,
  VehicleCreateValues,
  VerifyValues
} from 'src/models'

const prefix = '/user'

export const apiChangePassword = (payload: ChangePasswordValues) => {
  return baseApi.post(`${prefix}/change-password`, payload)
}

export const apiChangeEmail = (payload: ChangeEmailValues) => {
  return baseApi.post(`${prefix}/change-email`, payload)
}

export const apiChangeEmailResend = () => {
  return baseApi.post(`${prefix}/change-email/resend`)
}

export const apiChangeEmailVerify = (payload: VerifyValues) => {
  return baseApi.post(`${prefix}/change-email/verify`, payload)
}

export const apiGetVehicleInfo = () => {
  return baseApi.get(`${prefix}/vehicle`)
}

export const apiVehicleUpdate = (payload: VehicleCreateValues) => {
  return baseApi.post(`${prefix}/vehicle-information`, payload)
}

export const apiVehicleDelete = () => {
  return baseApi.delete(`${prefix}/vehicle/delete`)
}

export const apiGetProfile = () => {
  return baseApi.get(`${prefix}/profile`)
}

export const apiGetPrefecture = () => {
  return baseApi.get(`${prefix}/prefecture`)
}

export const apiGetZipCode = (zipcode: string) => {
  return baseApi.get(`${prefix}/zipcode/${zipcode}`)
}

export const apiGetAvailablePurchase = () => {
  return baseApi.get(`${prefix}/available-purchase-histories`)
}

export const apiRequestWithdraw = () => {
  return baseApi.post(`${prefix}/withdraw/request`)
}

export const apiResendWithdraw = () => {
  return baseApi.post(`${prefix}/withdraw/resend`)
}

export const apiVerifyWithdraw = (payload: VerifyValues) => {
  return baseApi.post(`${prefix}/withdraw/verify`, payload)
}

export const apiUpdateProfile = (payload: ProfileValues) => {
  return baseApi.put(`${prefix}/update-profile`, payload)
}

export const apiGetPaymentMethod = () => {
  return baseApi.get(`${prefix}/payment-method`)
}

export const apiCreateOrUpdatePaymentMethod = (
  payload: { token: string },
  isUpdate: boolean
) => {
  return isUpdate
    ? baseApi.put(`${prefix}/payment-method`, payload)
    : baseApi.post(`${prefix}/payment-method`, payload)
}

export const apiDeletePaymentMethod = () => {
  return baseApi.delete(`${prefix}/payment-method`)
}

export const apiSaveDeviceToken = (payload: DeviceTokenValues) => {
  return baseApi.post(`${prefix}/save-token`, payload)
}
