// uL21
import { Form, Formik, FormikProps } from 'formik'
import { useState } from 'react'
import FormItems from 'src/components/FormItems'
import LayoutMain from 'src/layouts/LayoutMain'
import Button from '@mui/material/Button'
import textJP from 'src/langs/ja'
import { resetPasswordInitialValues } from 'src/helpers/initialValues'
import { resetPasswordValidationSchema } from 'src/helpers/schema'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { clearBlock, setBlock } from 'src/stores/blockUI'
import { RESPONSE_STATUS } from 'src/constants/enum'
import ErrorResponse from 'src/components/ErrorResponse'
import path from 'src/routers/Path'
import { HandleApiProps, ResendPayload, VerifyValues } from 'src/models'
import {
  apiForgotPasswordResend,
  apiResetPassword
} from 'src/services/AuthService'
import InfoDialog from 'src/components/dialog/InfoDialog'
import TogglePasswordType from 'src/components/TogglePasswordType'
import { MAX_LENGTH_PASSWORD, onlyNumberRegex } from 'src/constants'

const ResetPassword = () => {
  const [typePassword, setTypePassword] = useState<string>('password')
  const [errorMess, setErrorMess] = useState<string>('')
  const [openInfo, setOpenInfo] = useState<boolean>(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const email = searchParams.get('email') as string

  const handleAPI = async ({
    apiCall,
    onSuccess,
    onError,
    onFinally
  }: HandleApiProps) => {
    try {
      setErrorMess('')
      dispatch(setBlock())
      const response = await apiCall
      if (response?.status === RESPONSE_STATUS.SUCCESS) {
        onSuccess?.()
      }
    } catch (error: any) {
      const message = error?.response?.data?.message
      setErrorMess(message)
      onError?.()
    } finally {
      onFinally?.()
      dispatch(clearBlock())
    }
  }

  const resendCode = (formikProps: FormikProps<VerifyValues>) => {
    const payload: ResendPayload = {
      email
    }
    handleAPI({
      apiCall: apiForgotPasswordResend(payload),
      onSuccess: () => {
        formikProps.setFieldValue('authentication_code_hash', '', false)
        setOpenInfo(true)
      }
    })
  }

  const verify = (values: VerifyValues) => {
    const payload: VerifyValues = {
      email,
      authentication_code_hash: values.authentication_code_hash,
      password: values.new_password
    }
    handleAPI({
      apiCall: apiResetPassword(payload),
      onSuccess: () => navigate(path.reset_password_completed)
    })
  }

  return (
    <LayoutMain
      title={textJP.reset_password.title}
      titleFormat={textJP.reset_password.title_format}
      description={textJP.reset_password.message_1}
      wrapperClassName='ly_container'
    >
      <Formik
        initialValues={resetPasswordInitialValues}
        validationSchema={resetPasswordValidationSchema}
        onSubmit={verify}
      >
        {(props: FormikProps<VerifyValues>) => {
          return (
            <Form>
              <dl className='bl_form ut_mt14'>
                <FormItems
                  name='authentication_code_hash'
                  label={textJP.common.authentication_code_hash}
                  placeholder={textJP.common.authentication_code_hash}
                  regex={onlyNumberRegex}
                  inputMode='numeric'
                />

                <FormItems
                  name='new_password'
                  label={textJP.common.new_password}
                  placeholder={textJP.common.password_notice}
                  type={typePassword}
                  maxLength={MAX_LENGTH_PASSWORD}
                >
                  <TogglePasswordType
                    typePassword={typePassword}
                    setTypePassword={setTypePassword}
                  >
                    <p
                      dangerouslySetInnerHTML={{
                        __html: textJP.common.password_notice_1
                      }}
                      className='el_txt_s ut_mt5'
                    ></p>
                  </TogglePasswordType>
                </FormItems>

                <div className='el_txt_center ut_mt20'>
                  <ErrorResponse errorMess={errorMess} />
                  <Button
                    type='submit'
                    variant='contained'
                    className='el_btn_common'
                  >
                    {textJP.btn.send}
                  </Button>
                </div>

                <div className='el_txt_center ut_mt14'>
                  <button
                    type='button'
                    onClick={() => resendCode(props)}
                    className='el_txt_link'
                  >
                    {textJP.common.resend_code}
                  </button>
                </div>
              </dl>
              <InfoDialog
                open={openInfo}
                setOpen={setOpenInfo}
                title={textJP.common.resend_code_message}
                closeLabel='OK'
              />
            </Form>
          )
        }}
      </Formik>
    </LayoutMain>
  )
}

export default ResetPassword
