import { Button } from '@mui/material'
import { FormikProps } from 'formik'
import textJP from 'src/langs/ja'
import { ContactValues } from 'src/models'

type Props = {
  formikProps: FormikProps<ContactValues>
  setActiveStep: (step: number | ((prev: number) => number)) => void
}

const Step2 = (props: Props) => {
  const { formikProps, setActiveStep } = props
  const { values } = formikProps
  return (
    <dl className='bl_borderList'>
      <dt className='bl_borderList_ttl'>{textJP.common.name}</dt>
      <dd className='bl_borderList_body'>{values.name}</dd>

      <dt className='bl_borderList_ttl'>{textJP.common.email}</dt>
      <dd className='bl_borderList_body'>{values.email}</dd>

      <dt className='bl_borderList_ttl'>{textJP.common.content}</dt>
      <dd className='bl_borderList_body contact-content'>{values.content}</dd>

      <div className='el_txt_center ut_mt20'>
        <Button
          type='submit'
          variant='contained'
          className='el_btn_common h-46px'
        >
          {textJP.btn.send}
        </Button>
        <button
          type='button'
          onClick={() => setActiveStep(prev => prev - 1)}
          className='el_btn_border el_btn_incIcon h-46px ut_mt20'
        >
          {textJP.btn.back}
        </button>
      </div>
    </dl>
  )
}

export default Step2
