// uN11
import { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import NotificationDetailData from 'src/components/NotificationDetailData'
import useFetchDetail from 'src/hooks/useFetchDetail'
import textJP from 'src/langs/ja'
import LayoutMain from 'src/layouts/LayoutMain'
import { apiGetNotificationDetail } from 'src/services/Notification'
import NotFound from 'src/pages/NotFound'
import path from 'src/routers/Path'
import { NOTIFICATION_TYPE } from 'src/constants/enum'

const NotificationDetail = () => {
  const { id } = useParams()
  const endpoint = useCallback(
    () => apiGetNotificationDetail(id as string),
    [id]
  )

  const { detail, errorType } = useFetchDetail({ endpoint })
  const navigate = useNavigate()

  return (
    <>
      {errorType ? (
        <NotFound type={errorType} />
      ) : (
        <LayoutMain
          title={textJP.notification.title}
          onBack={() => navigate(path.notification_list)}
        >
          <NotificationDetailData
            detail={detail}
            announcement_type={NOTIFICATION_TYPE.NORMAL}
          />
        </LayoutMain>
      )}
    </>
  )
}

export default NotificationDetail
