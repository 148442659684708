// uA11
import { useCallback, useEffect, useRef, useState } from 'react'
import { RESPONSE_STATUS } from 'src/constants/enum'
import LayoutMain from 'src/layouts/LayoutMain'
import { FAQValues } from 'src/models'
import { apiGetDataFAQ } from 'src/services/InformationService'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { CircularProgress } from '@mui/material'
import cx from 'classnames'
import textJP from 'src/langs/ja'
import useInfiniteScroll from 'src/hooks/useInfiniteScroll'
import RenderIf from 'src/components/RenderIf'
import { isMobileDevice, replaceNavigationTag } from 'src/utils'

type PaginateValues = {
  listFAQ: FAQValues[]
  page: number
  hasMore: boolean
  loadingMore: boolean
}

const FAQ = () => {
  const scrollableRef = useRef<any>()
  const [paginateProps, setPaginateProps] = useState<PaginateValues>({
    listFAQ: [],
    page: 1,
    hasMore: true,
    loadingMore: false
  })

  const getListFAQ = useCallback(async () => {
    const params = {
      page: paginateProps.page
    }
    const res = await apiGetDataFAQ(params)
    if (res?.status === RESPONSE_STATUS.SUCCESS) {
      const { data = [], last_page } = res?.data?.data || {}
      setPaginateProps(prev => {
        const updatedProps = {
          ...prev,
          listFAQ: [...prev.listFAQ, ...data],
          hasMore: paginateProps.page < last_page
        }
        setTimeout(() => {
          setPaginateProps(prev => ({
            ...prev,
            loadingMore: false
          }))
        }, 500)

        return updatedProps
      })
    }
  }, [paginateProps.page])

  useEffect(() => {
    if (paginateProps.listFAQ.length > 0) {
      replaceNavigationTag('.faq-content')
    }
  }, [paginateProps.listFAQ])

  useEffect(() => {
    getListFAQ()
  }, [getListFAQ])

  // Handle load more
  useInfiniteScroll({
    scrollableRef,
    hasMore: paginateProps.hasMore,
    onCallback: () => {
      if (!paginateProps.loadingMore) {
        setPaginateProps(prev => ({
          ...prev,
          page: prev.page + 1,
          loadingMore: true
        }))
      }
    }
  })

  return (
    <LayoutMain title={textJP.faq.title} wrapperClassName='ly_container'>
      <>
        <div
          className={cx('faq-content', {
            'loading-more': paginateProps.loadingMore,
            mobile: isMobileDevice()
          })}
        >
          <div ref={scrollableRef} className='h-full relative overflow-auto'>
            {paginateProps.listFAQ.map((item: FAQValues) => {
              return (
                <Accordion
                  disableGutters
                  key={item.id}
                  classes={{
                    root: 'accordion'
                  }}
                >
                  <AccordionSummary
                    expandIcon={
                      <ArrowDownwardIcon
                        classes={{ root: 'text-primary text-base' }}
                      />
                    }
                    classes={{
                      content: 'gap-2 bl_flex align_center',
                      root: 'px-0 gap-2'
                    }}
                  >
                    <span className='el_question break-word'>{item.title}</span>
                  </AccordionSummary>
                  <AccordionDetails classes={{ root: 'px-0 pt-0' }}>
                    <div
                      className='el_answer editor-content mt-0'
                      dangerouslySetInnerHTML={{ __html: item.content }}
                    ></div>
                  </AccordionDetails>
                </Accordion>
              )
            })}
          </div>
          <RenderIf isTrue={paginateProps.loadingMore}>
            <div className='bl_flex justify_center align_center mt-4'>
              <CircularProgress size={30} />
            </div>
          </RenderIf>
        </div>
      </>
    </LayoutMain>
  )
}

export default FAQ
