// uL22
import LayoutMain from 'src/layouts/LayoutMain'
import textJP from 'src/langs/ja'
import Button from '@mui/material/Button'
import path from 'src/routers/Path'
import { handleNavigateApp } from 'src/utils'
import { useNavigate } from 'react-router-dom'

const ResetPasswordCompleted = () => {
  const navigate = useNavigate()
  return (
    <LayoutMain
      title={textJP.reset_password_completed.title}
      titleFormat={textJP.reset_password_completed.title_format}
      description={textJP.reset_password_completed.message_1}
      wrapperClassName='ly_container'
    >
      <div className='el_txt_center ut_mt20'>
        <Button
          type='button'
          variant='contained'
          onClick={() =>
            handleNavigateApp({
              pathMobile: path.login_app,
              pathBrowser: path.login,
              navigate
            })
          }
          className='el_btn_common'
        >
          {textJP.btn.login}
        </Button>
      </div>
    </LayoutMain>
  )
}

export default ResetPasswordCompleted
