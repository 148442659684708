// uL20
import { Form, Formik } from 'formik'
import { useState } from 'react'
import FormItems from 'src/components/FormItems'
import LayoutMain from 'src/layouts/LayoutMain'
import Button from '@mui/material/Button'
import textJP from 'src/langs/ja'
import { forgotPassInitialValues } from 'src/helpers/initialValues'
import { forgotPassValidationSchema } from 'src/helpers/schema'
import ErrorResponse from 'src/components/ErrorResponse'
import { ForgotPasswordValues } from 'src/models'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { clearBlock, setBlock } from 'src/stores/blockUI'
import path from 'src/routers/Path'
import { apiForgotPassword } from 'src/services/AuthService'
import { RESPONSE_STATUS } from 'src/constants/enum'

const ForgotPassword = () => {
  const [errorMess, setErrorMess] = useState<string>('')
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const submit = async (values: ForgotPasswordValues) => {
    try {
      setErrorMess('')
      dispatch(setBlock())
      const newPath = `${path.reset_password}?email=${values.email}`
      const payload: ForgotPasswordValues = {
        ...values
      }
      const response = await apiForgotPassword(payload)
      if (response?.status === RESPONSE_STATUS.SUCCESS) {
        navigate(newPath)
      }
    } catch (error: any) {
      const message = error?.response?.data?.message
      setErrorMess(message)
    } finally {
      dispatch(clearBlock())
    }
  }

  return (
    <LayoutMain
      title={textJP.forgot_password.title}
      description={textJP.forgot_password.message_1}
      wrapperClassName='ly_container'
    >
      <Formik
        initialValues={forgotPassInitialValues}
        validationSchema={forgotPassValidationSchema}
        onSubmit={submit}
      >
        {() => {
          return (
            <Form>
              <dl className='bl_form ut_mt14'>
                <FormItems
                  name='email'
                  label={textJP.common.email}
                  placeholder={textJP.common.email}
                  inputMode='email'
                />

                <div className='el_txt_center ut_mt20'>
                  <ErrorResponse errorMess={errorMess} />
                  <Button
                    type='submit'
                    variant='contained'
                    className='el_btn_common'
                  >
                    {textJP.btn.send}
                  </Button>
                </div>
              </dl>
            </Form>
          )
        }}
      </Formik>
    </LayoutMain>
  )
}

export default ForgotPassword
