export enum RESPONSE_STATUS {
  SUCCESS = 200,
  CREATED = 201,
  UNAUTHORIZED = 401,
  NOT_FOUND = 404,
  INTERNAL = 500
}

export enum DISPLAY_TYPE {
  TERM_OF_USE = 1, // 利用規約
  PRIVACY_POLICY = 2, // プライバシーポリシー
  COMMERCIAL_TRANSACTIONS = 3, // 特定商取引法に基づく表記
  CONSTRUCTION = 4 // ご利用ガイド
}

export enum NOTIFICATION_TYPE {
  INDIVIDUAL = 1,
  NORMAL = 2
}

export enum NOTIFICATION_PRIORITY {
  IMPORTANT = 1,
  NORMAL = 2
}

export enum APPLICATION_ERROR_TYPE {
  NOT_FOUND = 1,
  SYSTEM_ERROR = 2,
  NETWORK_ERROR = 3
}

export enum CREDIT_BRAND {
  VISA = 'Visa',
  MASTERCARD = 'MasterCard',
  JCB = 'JCB',
  AMERICAN_EXPRESS = 'American Express',
  DINERS_CLUB = 'Diners Club',
  DISCOVER = 'Discover'
}

export enum LOCAL_STORAGE_KEYS {
  READ_NOTIFY = 'readNotify',
  READ_NOTIFY_WITHOUT_LOGIN = 'readNotifyWithoutLogin',
  NEW_NOTIFY = 'newNotify',
  APP_INFO = 'appInfo',
  PAYMENT_INFO = 'paymentInfo'
}
