import { Checkbox } from '@mui/material'
import textJP from 'src/langs/ja'

type TogglePasswordTypeProps = {
  children?: JSX.Element
  typePassword: string
  setTypePassword: (state: string) => void
}

const TogglePasswordType = (props: TogglePasswordTypeProps) => {
  const { children, typePassword, setTypePassword } = props
  const handleChangeTypePassword = () => {
    setTypePassword(typePassword === 'text' ? 'password' : 'text')
  }

  return (
    <>
      <div className='ut_mt5'>
        <Checkbox
          className='el_checkbox'
          checked={typePassword === 'text'}
          onChange={handleChangeTypePassword}
          id='toggle-password-type'
          sx={{
            '& .MuiSvgIcon-root': { fontSize: 18 }
          }}
        />
        <label htmlFor='toggle-password-type' className='el_label'>
          {textJP.login.toggle_password}
        </label>
        {children}
      </div>
    </>
  )
}

export default TogglePasswordType
