import textJP from 'src/langs/ja'

const Step3 = () => {
  return (
    <>
      <p className='el_txt_lg el_txt_bold500 el_txt_center ut_mt20'>
        {textJP.contact.contact_sent}
      </p>
      <p
        className='el_txt_paragraph17 ut_mt20'
        dangerouslySetInnerHTML={{ __html: textJP.contact.message }}
      ></p>
    </>
  )
}

export default Step3
