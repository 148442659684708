// uL32
import LayoutMain from 'src/layouts/LayoutMain'
import textJP from 'src/langs/ja'
import path from 'src/routers/Path'
import { handleNavigateApp } from 'src/utils'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'

const RegisterCompleted = () => {
  const navigate = useNavigate()
  return (
    <LayoutMain
      title={textJP.register_completed.title}
      description={textJP.register_completed.message_1}
      wrapperClassName='ly_container'
    >
      <div className='el_txt_center ut_mt30'>
        <Button
          type='button'
          onClick={() =>
            handleNavigateApp({
              pathMobile: path.login_app,
              pathBrowser: path.login,
              navigate
            })
          }
          className='el_btn_common'
        >
          {textJP.btn.login}
        </Button>
      </div>
    </LayoutMain>
  )
}

export default RegisterCompleted
