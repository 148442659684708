import { useCallback, useEffect, useState } from 'react'
import { DISPLAY_TYPE, RESPONSE_STATUS } from 'src/constants/enum'
import LayoutMain from 'src/layouts/LayoutMain'
import { apiDisplayDataByType } from 'src/services/InformationService'
import cx from 'classnames'
import {
  hasAnyValueObject,
  isMobileDevice,
  replaceNavigationTag
} from 'src/utils'

type DisplayDataProps = {
  type: DISPLAY_TYPE
}

const DisplayData = (props: DisplayDataProps) => {
  const { type } = props
  const [data, setData] = useState({
    title: '',
    content: ''
  })

  useEffect(() => {
    if (hasAnyValueObject(data)) {
      replaceNavigationTag('.editor-content')
    }
  }, [data])

  const getData = useCallback(async () => {
    const res = await apiDisplayDataByType({ type })
    if (res?.status === RESPONSE_STATUS.SUCCESS) {
      const { content = '', title = '' } = res?.data?.data || {}
      setData({ content, title })
    }
  }, [type])

  useEffect(() => {
    getData()
  }, [getData])

  return (
    <LayoutMain wrapperClassName='ly_container' title={data.title}>
      <div
        className={cx('editor-wrapper', {
          mobile: isMobileDevice()
        })}
      >
        <div
          className='editor-content'
          dangerouslySetInnerHTML={{
            __html: data.content
          }}
        ></div>
      </div>
    </LayoutMain>
  )
}

export default DisplayData
