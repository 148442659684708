import { NOTIFICATION_TYPE } from 'src/constants/enum'
import { baseApi } from 'src/services/base'

export const apiGetListNotification = (params: {
  page: number
  type: NOTIFICATION_TYPE
}) => {
  return baseApi.get(`/notifications`, { params })
}

export const apiGetNotificationDetail = (id: string) => {
  return baseApi.get(`/notification/${id}`)
}

export const apiGetNotificationIndividualDetail = (id: string) => {
  return baseApi.get(`/notification/individual/${id}`)
}

export const apiReadNotification = ({
  id,
  announcement_type
}: {
  id: number
  announcement_type: NOTIFICATION_TYPE
}) => {
  return baseApi.post(`/mark-as-read/${id}`, { announcement_type })
}

export const apiIsHaveUnreadNotification = () => {
  return baseApi.get("/notifications/unread")
}
