import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { NotificationValues } from 'src/models'

const notification: NotificationValues = {
  title: '',
  posting_date_and_time: '',
  id: 0
}

export const newNotification = createSlice({
  name: 'newNotification',
  initialState: notification,
  reducers: {
    saveNotification: (
      state: NotificationValues,
      value: PayloadAction<NotificationValues>
    ) => {
      return value.payload
    },
    clearNotification: () => {
      return { ...notification }
    }
  }
})

// Action creators are generated for each case reducer function
export const { saveNotification, clearNotification } = newNotification.actions

export default newNotification.reducer
