// uP20
import { Button } from '@mui/material'
import { Form, Formik, FormikProps } from 'formik'
import { useCallback, useEffect, useState } from 'react'
import FormItems, { TYPE_INPUT } from 'src/components/FormItems'
import {
  MAX_LENGTH_POSTCODE,
  hiraganaAndKatakanaRegex,
  onlyJapaneseCharacterRegex,
  onlyNumberRegex
} from 'src/constants'
import { profileInitialValues } from 'src/helpers/initialValues'
import { updateProfileValidationSchema } from 'src/helpers/schema'
import LayoutMain from 'src/layouts/LayoutMain'
import { OptionValues, ProfileValues } from 'src/models'
import {
  apiGetAvailablePurchase,
  apiGetPrefecture,
  apiGetZipCode,
  apiRequestWithdraw,
  apiUpdateProfile
} from 'src/services/UserService'
import textJP from 'src/langs/ja'
import { useNavigate } from 'react-router-dom'
import path from 'src/routers/Path'
import InfoDialog from 'src/components/dialog/InfoDialog'
import ConfirmDialog from 'src/components/dialog/ConfirmDialog'
import { useDispatch, useSelector } from 'react-redux'
import { clearBlock, setBlock } from 'src/stores/blockUI'
import { RootState } from 'src/stores'
import { saveProfile } from 'src/stores/Profile'
import { savePrefectureData } from 'src/stores/Prefecture'
import { RESPONSE_STATUS } from 'src/constants/enum'
import { handleNavigateApp } from 'src/utils'
import RenderIf from 'src/components/RenderIf'

const Profile = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const profile = useSelector((state: RootState) => state.profile)
  const lstPrefecture = useSelector((state: RootState) => state.prefecture)
  const [lstPurchase, setLstPurchase] = useState([])
  const [cannotWithdraw, setCannotWithdraw] = useState<boolean>(false)
  const [openWithdraw, setOpenWithdraw] = useState<boolean>(false)
  const [detail, setDetail] = useState(profileInitialValues)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false)
  const [postalCode, setPostalCode] = useState<string>('')
  const [notFoundPostCode, setNotFoundPostCode] = useState<string>('')

  const getListPrecture = useCallback(async () => {
    if (!lstPrefecture.length) {
      const response = await apiGetPrefecture()
      if (response?.status === RESPONSE_STATUS.SUCCESS) {
        const formatList: OptionValues[] = response?.data?.data?.map(
          (item: OptionValues) => ({ label: item, value: item })
        )
        dispatch(savePrefectureData(formatList))
      }
    }
  }, [dispatch, lstPrefecture])

  const getListAvailablePurchase = async () => {
    try {
      setLoading(true)
      const response = await apiGetAvailablePurchase()
      if (response?.status === RESPONSE_STATUS.SUCCESS) {
        setLstPurchase(response.data.data)
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getListAvailablePurchase()
  }, [])

  useEffect(() => {
    getListPrecture()
  }, [getListPrecture])

  useEffect(() => {
    if (lstPrefecture.length > 0) {
      setDetail({ ...profile })
      setPostalCode(profile.postal_code)
    }
  }, [lstPrefecture, profile])

  const onSearchZipCode = async (
    value: string,
    props: FormikProps<ProfileValues>
  ) => {
    try {
      const { setValues } = props
      if (value) {
        setLoadingSearch(true)
        const res = await apiGetZipCode(value)
        if (res?.status === RESPONSE_STATUS.SUCCESS) {
          const { province_name = '', district_name = '' } =
            res?.data?.data || {}
          setValues(prev => ({
            ...prev,
            prefecture_code: province_name,
            address1: district_name
          }))
          if (!res?.data?.data) {
            setNotFoundPostCode(textJP.common.post_code_not_found)
          }
        }
      } else {
        setValues(prev => ({
          ...prev,
          prefecture_code: '',
          address1: ''
        }))
      }
      setPostalCode(value)
    } finally {
      setTimeout(() => {
        setLoadingSearch(false)
      }, 500)
    }
  }

  const checkWithdrawal = () => {
    if (lstPurchase.length > 0) {
      setCannotWithdraw(true)
    } else {
      setOpenWithdraw(true)
    }
  }

  const requestWithdrawal = async () => {
    dispatch(setBlock())
    try {
      const res = await apiRequestWithdraw()
      if (res?.status === RESPONSE_STATUS.SUCCESS) {
        handleNavigateApp({
          pathMobile: path.withdraw_verify,
          pathBrowser: path.withdraw_verify,
          navigate
        })
      }
    } finally {
      dispatch(clearBlock())
    }
  }

  const updateProfile = async (values: ProfileValues) => {
    dispatch(setBlock())
    try {
      const res = await apiUpdateProfile(values)
      if (res?.status === RESPONSE_STATUS.SUCCESS) {
        dispatch(saveProfile(values))
        handleNavigateApp({
          pathMobile: path.close_web,
          pathBrowser: path.home,
          navigate
        })
      }
    } finally {
      dispatch(clearBlock())
    }
  }

  return (
    <LayoutMain
      title={textJP.profile.title}
      containerClassName='h-full'
      wrapperClassName='h-full'
    >
      <>
        <Formik
          enableReinitialize
          initialValues={detail}
          validationSchema={updateProfileValidationSchema}
          onSubmit={updateProfile}
        >
          {(props: FormikProps<ProfileValues>) => {
            return (
              <Form className='layout-wrapper'>
                <div className='ly_container'>
                  <dl className='bl_form'>
                    <div className='el_ttl_content bl_flex justify_between ut_mb10'>
                      <p>{textJP.profile.user_info}</p>
                      <span className='el_txt_red el_txt_bold400 el_txt_s'>
                        {textJP.common.notice_required}
                      </span>
                    </div>

                    <div className='bl_form'>
                      <label className='required' htmlFor='name_sei'>
                        {textJP.profile.full_name}
                      </label>
                      <div className='bl_grid'>
                        <FormItems
                          name='name_sei'
                          regex={onlyJapaneseCharacterRegex}
                          placeholder={textJP.profile.sei}
                        ></FormItems>
                        <FormItems
                          name='name_mei'
                          regex={onlyJapaneseCharacterRegex}
                          placeholder={textJP.profile.mei}
                        ></FormItems>
                      </div>
                    </div>
                    <div className='bl_form'>
                      <label className='required' htmlFor='name_sei_kana'>
                        {textJP.profile.full_name_kana}
                      </label>
                      <div className='bl_grid'>
                        <FormItems
                          name='name_sei_kana'
                          regex={hiraganaAndKatakanaRegex}
                          placeholder={textJP.profile.last_name_kana}
                        ></FormItems>
                        <FormItems
                          name='name_mei_kana'
                          regex={hiraganaAndKatakanaRegex}
                          placeholder={textJP.profile.first_name_kana}
                        ></FormItems>
                      </div>
                    </div>
                    <FormItems
                      required
                      inputMode='numeric'
                      name='postal_code'
                      label={textJP.common.postal_code}
                      placeholder='1234567'
                      description={textJP.profile.postal_code_des}
                      regex={onlyNumberRegex}
                      loading={loadingSearch}
                      maxLength={MAX_LENGTH_POSTCODE}
                      showIcon
                      onChange={() => setNotFoundPostCode('')}
                      onBlur={event => {
                        const { value = '' } = event.target || {}
                        if (value !== postalCode) {
                          onSearchZipCode(value, props)
                        }
                      }}
                    >
                      <RenderIf isTrue={!!notFoundPostCode}>
                        <span className='el_txt_xs el_txt_red'>
                          {notFoundPostCode}
                        </span>
                      </RenderIf>
                    </FormItems>
                    <FormItems
                      typeInput={TYPE_INPUT.SELECT}
                      required
                      name='prefecture_code'
                      label={textJP.common.prefecture_code}
                      placeholder={textJP.common.please_select}
                      optionsList={lstPrefecture}
                    />
                    <FormItems
                      required
                      name='address1'
                      label={textJP.common.address1}
                      placeholder={textJP.common.address1}
                    />
                    <FormItems
                      name='address2'
                      label={textJP.common.address2}
                      placeholder={textJP.common.address2}
                    />
                    <FormItems
                      required
                      inputMode='numeric'
                      name='phone_number'
                      label={textJP.common.phone_number}
                      placeholder='09012345678'
                      description={textJP.profile.phone_description}
                      maxLength={15}
                      regex={onlyNumberRegex}
                    />
                  </dl>
                </div>

                <div className='ut_bg_gray'>
                  <div className='ly_container'>
                    <div className='el_ttl_content'>
                      {textJP.profile.account_info}
                    </div>
                    <div className='bl_flex justify_between align_center ut_mt10'>
                      <span>{textJP.common.email}</span>
                      <button
                        type='button'
                        onClick={() =>
                          handleNavigateApp({
                            pathMobile: path.change_email,
                            pathBrowser: path.change_email,
                            navigate
                          })
                        }
                        className='el_btn_mini'
                      >
                        {textJP.btn.change}
                      </button>
                    </div>
                    <div className='bl_flex justify_between align_center ut_mt10'>
                      <span>{textJP.common.password}</span>
                      <button
                        type='button'
                        onClick={() =>
                          handleNavigateApp({
                            pathMobile: path.change_password,
                            pathBrowser: path.change_password,
                            navigate
                          })
                        }
                        className='el_btn_mini'
                      >
                        {textJP.btn.change}
                      </button>
                    </div>
                  </div>
                </div>

                <p
                  style={{ marginBlock: '23px' }}
                  className='ly_container el_txt_right'
                >
                  <button
                    disabled={loading}
                    type='button'
                    onClick={checkWithdrawal}
                    className='el_txt_link'
                  >
                    {textJP.profile.withdraw}
                  </button>
                </p>

                <div className='ly_container bl_flex flex-col justify_end flex-1 btn-sticky-bottom'>
                  <div className='el_txt_center'>
                    <Button
                      type='submit'
                      variant='contained'
                      className='el_btn_common'
                    >
                      {textJP.btn.save}
                    </Button>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>

        <InfoDialog
          open={cannotWithdraw}
          setOpen={setCannotWithdraw}
          title={textJP.profile.dialog.no_withdraw_title}
          description={textJP.profile.dialog.no_withdraw_des}
        />
        <ConfirmDialog
          open={openWithdraw}
          setOpen={setOpenWithdraw}
          title={textJP.profile.dialog.confirm_withdraw_title}
          description={textJP.profile.dialog.confirm_withdraw_des}
          onConfirm={requestWithdrawal}
        />
      </>
    </LayoutMain>
  )
}

export default Profile
