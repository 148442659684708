import cx from 'classnames'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import RenderIf from 'src/components/RenderIf'
import Header from 'src/components/common/Header'
import HeaderWeb from 'src/components/common/HeaderWeb'
import path from 'src/routers/Path'
import { isMobileDevice } from 'src/utils'

type LayoutMainProps = {
  children: JSX.Element
  title?: string
  titleFormat?: string
  description?: string
  showHeader?: boolean
  showHeaderWeb?: boolean
  containerClassName?: string
  wrapperClassName?: string
  onBack?: () => void
  showBackBtn?: boolean
}

// if wrap routes into layout then use Outlet (reat-router-dom), otherwise use children if specific layout in each page
const LayoutMain = (props: LayoutMainProps) => {
  const {
    children,
    title,
    titleFormat,
    onBack,
    containerClassName,
    showHeader = true,
    showHeaderWeb,
    wrapperClassName,
    description,
    showBackBtn
  } = props

  const arrayCheck = [path.reset_password, path.reset_password_completed]
  const location = useLocation()

  const result = (
    arrayCheck.includes(location.pathname) ? titleFormat : title
  ) as string

  useEffect(() => {
    document.title = result || 'PLAMOBI'
  }, [result])

  return (
    <div className='main-container'>
      <RenderIf isTrue={(showHeader && !isMobileDevice()) as boolean}>
        <Header title={title} showBackBtn={showBackBtn} onBack={onBack} />
      </RenderIf>

      <RenderIf isTrue={showHeaderWeb as boolean}>
        <HeaderWeb />
      </RenderIf>

      <main id='main-content' className='main-content overflow-auto'>
        <div className={cx(containerClassName)}>
          <div className={cx('layout-wrapper', wrapperClassName)}>
            <RenderIf isTrue={!!description}>
              <p
                className='el_txt_rg el_txt_paragraph17'
                dangerouslySetInnerHTML={{ __html: description as string }}
              ></p>
            </RenderIf>

            {/* render by route */}
            {children}
          </div>
        </div>
      </main>
    </div>
  )
}

export default LayoutMain
