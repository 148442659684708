type RenderIfProps = {
  children: JSX.Element
  isTrue: boolean
}

const RenderIf = (props: RenderIfProps) => {
  const { children, isTrue } = props
  return <>{isTrue ? children : null}</>
}

export default RenderIf
