import Prefecture from './Prefecture'
import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'
import TokenReducer from 'src/stores/Token'
import PreviousRouteReducer from 'src/stores/PreviousRoute'
import ProfileReducer from 'src/stores/Profile'
import BlockUIReducer from 'src/stores/blockUI'
import DeviceTokenReducer from 'src/stores/DeviceToken'
import NewNotification from './NewNotification'
import InitPayJP from './InitPayJP'

const reducers = combineReducers({
  token: TokenReducer,
  previousRoute: PreviousRouteReducer,
  blockUI: BlockUIReducer,
  profile: ProfileReducer,
  prefecture: Prefecture,
  deviceToken: DeviceTokenReducer,
  newNotification: NewNotification,
  initPayJP: InitPayJP,
})

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['_persist', 'blockUI', 'prefecture', 'initPayJP'] // use when don't want to persist
  // whitelist: [] // use when want to persist
}

const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk]
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
