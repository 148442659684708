// uP25
import textJP from 'src/langs/ja'
import LayoutMain from 'src/layouts/LayoutMain'

const WithdrawCompleted = () => {
  return (
    <LayoutMain
      title={textJP.withdraw_completed.title}
      wrapperClassName='ly_container'
    >
      <div className='el_txt_rg el_txt_paragraph17'>
        <p>{textJP.withdraw_completed.message_1}</p>
        <p>{textJP.withdraw_completed.message_2}</p>
        <p>{textJP.withdraw_completed.message_3}</p>

        <div className='ut_mt20'>
          <p>{textJP.withdraw_completed.message_4}</p>
        </div>
      </div>
    </LayoutMain>
  )
}

export default WithdrawCompleted
