import { useDispatch } from 'react-redux'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { store } from 'src/stores'
import { saveRoute } from 'src/stores/PreviousRoute'

const AuthRoute = ({ children }: { children?: JSX.Element }) => {
  const token = store.getState().token
  const location = useLocation()
  const dispatch = useDispatch()

  if (!token) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    const name = encodeURIComponent(location.pathname)
    dispatch(saveRoute(location.pathname))
    return (
      <Navigate
        to={`login?redirect=${name}`}
        state={{ from: location }}
        replace
      />
    )
  }
  return <Outlet />
}

export default AuthRoute
