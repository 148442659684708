// uN10
import { useCallback, useEffect, useRef, useState } from 'react'
import {
  NOTIFICATION_PRIORITY,
  NOTIFICATION_TYPE,
  RESPONSE_STATUS
} from 'src/constants/enum'
import LayoutMain from 'src/layouts/LayoutMain'
import cx from 'classnames'
import { NotificationValues } from 'src/models'
import {
  apiGetListNotification,
  apiIsHaveUnreadNotification
} from 'src/services/Notification'
import useInfiniteScroll from 'src/hooks/useInfiniteScroll'
import { CircularProgress } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import path from 'src/routers/Path'
import textJP from 'src/langs/ja'
import {
  filterDuplicateItemList,
  getNotifyPublishDate,
  handleNavigateApp,
  isMobileDevice
} from 'src/utils'
import { firebaseApp } from 'src/firebase'
import RenderIf from 'src/components/RenderIf'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'src/stores'
import { clearNotification } from 'src/stores/NewNotification'
import { LOCAL_STORAGE_KEYS } from 'src/constants/enum'

type PaginateValues = {
  listNotification: NotificationValues[]
  page: number
  hasMore: boolean
  loadingMore: boolean
  loading: boolean
  selectedType: any
}

const NotificationList = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const scrollableRef = useRef<any>()
  const [paginateProps, setPaginateProps] = useState<PaginateValues>({
    listNotification: [],
    page: 1,
    hasMore: true,
    loadingMore: false,
    loading: true,
    selectedType: null
  })
  const newNotification = useSelector(
    (state: RootState) => state.newNotification
  )
  const token = useSelector((state: RootState) => state.token)

  // set selected tabs based on unread notification type
  useEffect(() => {
    try {
      const getIsHaveUnreadNotification = async () => {
        const res = await apiIsHaveUnreadNotification()
        if (res.status === RESPONSE_STATUS.SUCCESS) {
          const { notification_type } = res?.data?.data || {}
          setPaginateProps(prev => ({
            ...prev,
            selectedType: Number(notification_type)
          }))
        }
      }

      getIsHaveUnreadNotification()
    } catch {
      setPaginateProps(prev => ({
        ...prev,
        selectedType: NOTIFICATION_TYPE.NORMAL
      }))
    }
  }, [])

  const formatNewNotification = (data: any) => {
    const result = {
      title: data?.title,
      posting_date_and_time: data?.created_at,
      id: data.id ? Number(data.id) : null,
      has_read: false
    }
    return result
  }

  // handle new notification in other window/page (from detail)
  useEffect(() => {
    const handleMessage = (event: any) => {
      if (event.key === LOCAL_STORAGE_KEYS.READ_NOTIFY) {
        const data = JSON.parse(event.newValue)
        if (data?.id) {
          const updatedNotifications = [...paginateProps.listNotification].map(
            notification =>
              notification.id === Number(data?.id)
                ? { ...notification, has_read: true }
                : notification
          )
          setPaginateProps(prev => ({
            ...prev,
            listNotification: [...updatedNotifications]
          }))
        }
      }

      if (event.key === LOCAL_STORAGE_KEYS.READ_NOTIFY_WITHOUT_LOGIN) {
        const data = JSON.parse(event.newValue)
        if (Array.isArray(data) && data?.length) {
          const updatedNotifications = paginateProps.listNotification.map(
            notification => {
              isApplyStyleForUnread(notification)
              return {
                ...notification
              }
            }
          )
          setPaginateProps(prev => ({
            ...prev,
            listNotification: updatedNotifications
          }))
        }
      }

      if (
        event.key === LOCAL_STORAGE_KEYS.NEW_NOTIFY &&
        paginateProps.selectedType === NOTIFICATION_TYPE.INDIVIDUAL &&
        isMobileDevice()
      ) {
        const data = JSON.parse(JSON.parse(event.newValue))
        if (data?.id) {
          const result: any = formatNewNotification(data)
          setPaginateProps(prev => ({
            ...prev,
            listNotification: filterDuplicateItemList([
              result,
              ...prev.listNotification
            ])
          }))
        }
      }
    }

    window.addEventListener('storage', handleMessage)

    return () => {
      window.removeEventListener('storage', handleMessage)
    }
  })

  const getListNotification = useCallback(async () => {
    if (paginateProps.selectedType) {
      if (paginateProps.page === 1) {
        setPaginateProps(prev => ({
          ...prev,
          loading: true
        }))
      }
      const params = {
        page: paginateProps.page,
        type: paginateProps.selectedType
      }
      const res = await apiGetListNotification(params)
      if (res?.status === RESPONSE_STATUS.SUCCESS) {
        setPaginateProps(prev => {
          const updatedProps = {
            ...prev,
            listNotification: filterDuplicateItemList([
              ...prev.listNotification,
              ...(res?.data?.data?.data || [])
            ]),
            loading: false,
            hasMore: paginateProps.page < res?.data?.data?.last_page
          }
          setTimeout(() => {
            setPaginateProps(prev => ({
              ...prev,
              loadingMore: false
            }))
          }, 500)

          return updatedProps
        })
      }
    }
  }, [paginateProps.page, paginateProps.selectedType])

  useEffect(() => {
    getListNotification()
  }, [getListNotification])

  useEffect(() => {
    if (!isMobileDevice()) {
      let unsubscribe: any = null
      if (paginateProps.selectedType === NOTIFICATION_TYPE.INDIVIDUAL) {
        const messaging = firebaseApp.messaging()
        unsubscribe = messaging.onMessage((payload: any) => {
          const data: any = formatNewNotification(payload.data)
          setPaginateProps(prev => ({
            ...prev,
            listNotification: filterDuplicateItemList([
              data,
              ...prev.listNotification
            ])
          }))
        })
      }

      // Cleanup the listener when the component unmounts or selectedType changes
      return () => {
        if (unsubscribe) {
          unsubscribe() // Detach the listener if it was set up
        }
      }
    }
  }, [paginateProps.selectedType])

  // handle new notification in current window/page (from detail)
  useEffect(() => {
    if (
      newNotification.id &&
      paginateProps.selectedType === NOTIFICATION_TYPE.INDIVIDUAL &&
      isMobileDevice()
    ) {
      const data: any = formatNewNotification(newNotification)
      setPaginateProps(prev => ({
        ...prev,
        listNotification: filterDuplicateItemList([
          data,
          ...prev.listNotification
        ])
      }))
      dispatch(clearNotification())
    }
  }, [newNotification, dispatch, paginateProps.selectedType])

  // handle load more
  useInfiniteScroll({
    scrollableRef,
    hasMore: paginateProps.hasMore,
    onCallback: () => {
      if (!paginateProps.loadingMore) {
        setPaginateProps(prev => ({
          ...prev,
          page: prev.page + 1,
          loadingMore: true
        }))
      }
    }
  })

  const updateType = (selected: NOTIFICATION_TYPE) => {
    if (selected !== paginateProps.selectedType) {
      setPaginateProps(prev => ({
        ...prev,
        page: 1,
        listNotification: [],
        selectedType: selected,
        loading: true
      }))
    }
  }

  const getDetailLink = (id: number) => {
    return paginateProps.selectedType === NOTIFICATION_TYPE.INDIVIDUAL
      ? path.notification_individual_detail(id)
      : path.notification_detail(id)
  }

  const isApplyStyleForUnread = (notification: NotificationValues) => {
    if (token) {
      return !notification.has_read
    }
    const jsonArray = localStorage.getItem(
      LOCAL_STORAGE_KEYS.READ_NOTIFY_WITHOUT_LOGIN
    )
    const array = jsonArray ? JSON.parse(jsonArray) : []
    const isNotificationExistInStore = array.some(
      (item: any) =>
        item.id === notification.id && item.type === paginateProps.selectedType
    )
    return !isNotificationExistInStore && !notification.has_read
  }

  return (
    <LayoutMain title={textJP.notification.title}>
      <>
        <div className='bl_tabWrapper'>
          <button
            onClick={() => updateType(NOTIFICATION_TYPE.INDIVIDUAL)}
            type='button'
            className={cx('bl_tab_item', {
              selected:
                paginateProps.selectedType === NOTIFICATION_TYPE.INDIVIDUAL
            })}
          >
            {textJP.notification.my_self}
          </button>
          <button
            onClick={() => updateType(NOTIFICATION_TYPE.NORMAL)}
            type='button'
            className={cx('bl_tab_item', {
              selected: paginateProps.selectedType === NOTIFICATION_TYPE.NORMAL
            })}
          >
            {textJP.notification.news}
          </button>
        </div>

        <RenderIf
          isTrue={
            paginateProps.listNotification.length > 0 && !paginateProps.loading
          }
        >
          <div className='ly_container'>
            <div
              className={cx('notification-content', {
                'loading-more': paginateProps.loadingMore,
                mobile: isMobileDevice()
              })}
            >
              <div
                ref={scrollableRef}
                className='h-full relative overflow-auto'
              >
                <ul className='bl_newsList'>
                  {paginateProps.listNotification.map(
                    (item: NotificationValues) => {
                      return (
                        <li className='bl_newsList_item mr-2' key={item.id}>
                          <button
                            style={{ width: '100%', textAlign: 'left' }}
                            type='button'
                            onClick={() =>
                              handleNavigateApp({
                                pathMobile: getDetailLink(item.id),
                                pathBrowser: getDetailLink(item.id),
                                navigate
                              })
                            }
                            className={cx({
                              el_txt_bold700: isApplyStyleForUnread(item)
                            })}
                          >
                            <p className='el_txt_md truncate'>{item.title}</p>
                            <p className='el_txt_s ut_inlineBlock'>
                              {getNotifyPublishDate(item)}
                            </p>

                            <RenderIf
                              isTrue={
                                item.priority ===
                                NOTIFICATION_PRIORITY.IMPORTANT
                              }
                            >
                              <p className='el_txt_s ut_inlineBlock ut_ml20'>
                                {textJP.notification.important}
                              </p>
                            </RenderIf>
                          </button>
                        </li>
                      )
                    }
                  )}
                </ul>
              </div>
              <RenderIf isTrue={paginateProps.loadingMore}>
                <div className='bl_flex justify_center align_center mt-4'>
                  <CircularProgress size={30} />
                </div>
              </RenderIf>
            </div>
          </div>
        </RenderIf>

        <RenderIf
          isTrue={
            !paginateProps.listNotification.length &&
            !paginateProps.loading &&
            paginateProps.selectedType === NOTIFICATION_TYPE.INDIVIDUAL
          }
        >
          <div className='ly_container'>
            {textJP.notification.no_my_self_mess}
          </div>
        </RenderIf>

        <RenderIf
          isTrue={
            !paginateProps.listNotification.length &&
            !paginateProps.loading &&
            paginateProps.selectedType === NOTIFICATION_TYPE.NORMAL
          }
        >
          <div className='ly_container'>{textJP.notification.no_news}</div>
        </RenderIf>
      </>
    </LayoutMain>
  )
}

export default NotificationList
