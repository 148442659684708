// uA20
import { Form, Formik, FormikProps } from 'formik'
import LayoutMain from 'src/layouts/LayoutMain'
import { useState } from 'react'
import ContactStepper from 'src/components/ContactStepper'
import { contactInitialValues } from 'src/helpers/initialValues'
import { contactValidationSchema } from 'src/helpers/schema'
import Step1 from './Step1'
import Step2 from './Step2'
import { ContactValues } from 'src/models'
import { RESPONSE_STATUS } from 'src/constants/enum'
import { useDispatch } from 'react-redux'
import { clearBlock, setBlock } from 'src/stores/blockUI'
import Step3 from './Step3'
import { apiContactStore } from 'src/services/InformationService'
import textJP from 'src/langs/ja'
import RenderIf from 'src/components/RenderIf'

const ContactForm = () => {
  const [activeStep, setActiveStep] = useState(1)
  const [acceptCondition, setAcceptCondition] = useState<boolean>(false)
  const dispatch = useDispatch()

  const create = async (values: ContactValues) => {
    if (activeStep === 1) {
      setActiveStep(prev => prev + 1)
      return
    }

    dispatch(setBlock())
    try {
      const res = await apiContactStore(values)
      if (res?.status === RESPONSE_STATUS.CREATED) {
        setActiveStep(prev => prev + 1)
      }
    } finally {
      dispatch(clearBlock())
    }
  }

  return (
    <LayoutMain title={textJP.my_page.contact} wrapperClassName='my-6'>
      <>
        <ContactStepper step={activeStep} />

        <div className='ly_container'>
          <Formik
            initialValues={contactInitialValues}
            validationSchema={contactValidationSchema}
            onSubmit={create}
          >
            {(props: FormikProps<ContactValues>) => {
              return (
                <Form>
                  <RenderIf isTrue={activeStep === 1}>
                    <Step1
                      acceptCondition={acceptCondition}
                      setAcceptCondition={setAcceptCondition}
                    />
                  </RenderIf>
                  <RenderIf isTrue={activeStep === 2}>
                    <Step2 formikProps={props} setActiveStep={setActiveStep} />
                  </RenderIf>
                  <RenderIf isTrue={activeStep === 3}>
                    <Step3 />
                  </RenderIf>
                </Form>
              )
            }}
          </Formik>
        </div>
      </>
    </LayoutMain>
  )
}

export default ContactForm
