import { emailRegex, passwordRegex } from 'src/constants'
import * as Yup from 'yup'

export const verifyValidationSchema = () => {
  return Yup.object().shape({
    authentication_code_hash: Yup.string().required().trim()
  })
}

export const resetPasswordValidationSchema = () => {
  return Yup.object().shape({
    authentication_code_hash: Yup.string().required().trim(),
    new_password: Yup.string().required().trim().matches(passwordRegex)
  })
}

export const loginValidationSchema = () => {
  return Yup.object().shape({
    email: Yup.string().required().trim().matches(emailRegex),
    password: Yup.string().required().trim().matches(passwordRegex)
  })
}

export const registerValidationSchema = () => {
  return Yup.object().shape({
    email: Yup.string().required().trim().matches(emailRegex),
    password: Yup.string().required().trim().matches(passwordRegex)
  })
}

export const forgotPassValidationSchema = () => {
  return Yup.object().shape({
    email: Yup.string().required().trim().matches(emailRegex)
  })
}

export const changePassValidationSchema = () => {
  return Yup.object().shape({
    new_password: Yup.string().required().trim().matches(passwordRegex)
  })
}

export const changeEmailValidationSchema = () => {
  return Yup.object().shape({
    current_email: Yup.string().required().trim().matches(emailRegex),
    email_temp: Yup.string().required().trim().matches(emailRegex)
  })
}

export const vehicleCreateValidationSchema = () => {
  return Yup.object().shape({
    place_name: Yup.string().required().trim(),
    classification_number: Yup.string().required().trim(),
    car_number_hiragana: Yup.string().required().trim(),
    serial_number: Yup.string()
      .required()
      .trim()
      .matches(/^\d{1,4}$/, '1～4の間の数字を入力してください。')
  })
}

export const updateProfileValidationSchema = () => {
  return Yup.object().shape({
    name_mei_kana: Yup.string().required().trim(),
    name_sei_kana: Yup.string().required().trim(),
    name_mei: Yup.string().required().trim(),
    name_sei: Yup.string().required().trim(),
    postal_code: Yup.string().required().trim(),
    prefecture_code: Yup.string().required().trim(),
    address1: Yup.string().required().trim(),
    address2: Yup.string(),
    phone_number: Yup.string().required().trim()
  })
}

export const contactValidationSchema = () => {
  return Yup.object().shape({
    name: Yup.string().required().trim(),
    email: Yup.string().required().trim().matches(emailRegex),
    content: Yup.string().required().trim()
  })
}
