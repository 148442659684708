import { useSelector } from 'react-redux'
import { RootState } from 'src/stores'
import RenderIf from 'src/components/RenderIf'
import { isMobileDevice } from 'src/utils'

const BlockUIComponent = () => {
  const blocked = useSelector((state: RootState) => state.blockUI)
  return (
    <RenderIf isTrue={blocked}>
      <div className='block-ui'>
        <RenderIf isTrue={isMobileDevice()}>
          <div className='loader'></div>
        </RenderIf>
      </div>
    </RenderIf>
  )
}

export default BlockUIComponent
