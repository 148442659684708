import cx from 'classnames'
import textJP from 'src/langs/ja'

type ContactStepperProps = {
  step: number
}
const ContactStepper = (props: ContactStepperProps) => {
  const { step } = props
  return (
    <div className='bl_flex justify_center pt-9 pb-6 el_shadow'>
      <div className='step-bar'>
        <div
          className={cx('step step_1', step === 1 ? 'bg-primary' : 'bg_gray_2')}
        ></div>
        <div
          className={cx('step step_2', step === 2 ? 'bg-primary' : 'bg_gray_2')}
        ></div>
        <div
          className={cx('step step_3', step === 3 ? 'bg-primary' : 'bg_gray_2')}
        ></div>
        <div
          className={cx(
            'text_step step_1',
            step === 1 ? 'text-primary' : 'text_gray_2'
          )}
        >
          {textJP.contact.step_1}
        </div>
        <div
          className={cx(
            'text_step step_2',
            step === 2 ? 'text-primary' : 'text_gray_2'
          )}
        >
          {textJP.contact.step_2}
        </div>
        <div
          className={cx(
            'text_step step_3',
            step === 3 ? 'text-primary' : 'text_gray_2'
          )}
        >
          {textJP.contact.step_3}
        </div>
      </div>
    </div>
  )
}

export default ContactStepper
