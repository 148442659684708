import { createSlice } from '@reduxjs/toolkit'

const blocked: boolean = false

export const blockUI = createSlice({
  name: 'blockUI',
  initialState: blocked,
  reducers: {
    setBlock: () => {
      const element = document.getElementById('main-content')
      if (element) {
        element.style.cssText = 'overflow: hidden !important'
      }
      return true
    },
    clearBlock: () => {
      const element = document.getElementById('main-content')
      if (element) {
        element.style.cssText = ''
      }

      return false
    }
  }
})

// Action creators are generated for each case reducer function
export const { setBlock, clearBlock } = blockUI.actions

export default blockUI.reducer
