// uP22
import { Form, Formik } from 'formik'
import { useState } from 'react'
import FormItems from 'src/components/FormItems'
import LayoutMain from 'src/layouts/LayoutMain'
import Button from '@mui/material/Button'
import textJP from 'src/langs/ja'
import { changePasswordInitialValues } from 'src/helpers/initialValues'
import { changePassValidationSchema } from 'src/helpers/schema'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { clearBlock, setBlock } from 'src/stores/blockUI'
import { RESPONSE_STATUS } from 'src/constants/enum'
import ErrorResponse from 'src/components/ErrorResponse'
import path from 'src/routers/Path'
import { ChangePasswordValues } from 'src/models'
import { apiChangePassword } from 'src/services/UserService'
import TogglePasswordType from 'src/components/TogglePasswordType'
import { handleNavigateApp } from 'src/utils'
import { MAX_LENGTH_PASSWORD } from 'src/constants'

const ChangePassword = () => {
  const [typePassword, setTypePassword] = useState<string>('password')
  const [errorMess, setErrorMess] = useState<string>('')
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const update = async (values: ChangePasswordValues) => {
    try {
      setErrorMess('')
      dispatch(setBlock())
      const payload = {
        password: values.new_password
      }
      const repsonse = await apiChangePassword(payload)
      if (repsonse?.status === RESPONSE_STATUS.SUCCESS) {
        handleNavigateApp({
          pathMobile: path.close_web,
          pathBrowser: path.profile,
          navigate
        })
      }
    } catch (error: any) {
      const message = error?.response?.data?.message
      setErrorMess(message)
    } finally {
      dispatch(clearBlock())
    }
  }

  return (
    <LayoutMain
      title={textJP.change_password.title}
      wrapperClassName='ly_container'
    >
      <Formik
        initialValues={changePasswordInitialValues}
        validationSchema={changePassValidationSchema}
        onSubmit={update}
      >
        {() => {
          return (
            <Form>
              <dl className='bl_form'>
                <FormItems
                  name='new_password'
                  label={textJP.common.new_password}
                  placeholder={textJP.common.password_notice}
                  type={typePassword}
                  maxLength={MAX_LENGTH_PASSWORD}
                >
                  <TogglePasswordType
                    typePassword={typePassword}
                    setTypePassword={setTypePassword}
                  >
                    <p
                      dangerouslySetInnerHTML={{
                        __html: textJP.common.password_notice_1
                      }}
                      className='el_txt_s ut_mt5'
                    ></p>
                  </TogglePasswordType>
                </FormItems>

                <div className='el_txt_center ut_mt20'>
                  <ErrorResponse errorMess={errorMess} />
                  <Button
                    type='submit'
                    variant='contained'
                    className='el_btn_common'
                  >
                    {textJP.btn.save}
                  </Button>
                </div>
              </dl>
            </Form>
          )
        }}
      </Formik>
    </LayoutMain>
  )
}

export default ChangePassword
