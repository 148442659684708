import { Navigate, Outlet } from 'react-router-dom'
import { store } from 'src/stores'
import path from './Path'

const UnAuthRoute = () => {
  const token = store.getState().token
  const previousRoute = store.getState().previousRoute
  if (token) {
    return <Navigate to={previousRoute || path.home} />
  }
  return <Outlet />
}

export default UnAuthRoute
