// uP24
import { Form, Formik, FormikProps } from 'formik'
import { useState } from 'react'
import FormItems from 'src/components/FormItems'
import LayoutMain from 'src/layouts/LayoutMain'
import Button from '@mui/material/Button'
import textJP from 'src/langs/ja'
import { verifyInitialValues } from 'src/helpers/initialValues'
import { verifyValidationSchema } from 'src/helpers/schema'
import ErrorResponse from 'src/components/ErrorResponse'
import { VerifyValues } from 'src/models'
import { useDispatch } from 'react-redux'
import { clearBlock, setBlock } from 'src/stores/blockUI'
import { RESPONSE_STATUS } from 'src/constants/enum'
import path from 'src/routers/Path'
import { apiResendWithdraw, apiVerifyWithdraw } from 'src/services/UserService'
import InfoDialog from 'src/components/dialog/InfoDialog'
import { useNavigate } from 'react-router-dom'
import { clearLocalStorage } from 'src/utils'
import { onlyNumberRegex } from 'src/constants'

const WithdrawVerify = () => {
  const [errorMess, setErrorMess] = useState<string>('')
  const [openInfo, setOpenInfo] = useState<boolean>(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleAPI = async ({ apiUrl, onSuccess, onError, onFinally }: any) => {
    try {
      setErrorMess('')
      dispatch(setBlock())
      const repsonse = await apiUrl
      if (repsonse?.status === RESPONSE_STATUS.SUCCESS) {
        onSuccess && onSuccess()
      }
    } catch (error: any) {
      const message = error?.response?.data?.message
      setErrorMess(message)
      onError && onError()
    } finally {
      onFinally && onFinally()
      dispatch(clearBlock())
    }
  }

  const resendCode = async (formikProps: FormikProps<VerifyValues>) => {
    handleAPI({
      apiUrl: apiResendWithdraw(),
      onSuccess: () => {
        formikProps.setFieldValue('authentication_code_hash', '', false)
        setOpenInfo(true)
      }
    })
  }

  const verify = async (values: VerifyValues) => {
    const payload: VerifyValues = {
      authentication_code_hash: values.authentication_code_hash
    }
    handleAPI({
      apiUrl: apiVerifyWithdraw(payload),
      onSuccess: () => {
        clearLocalStorage()
        navigate(path.withdraw_completed)
      }
    })
  }

  return (
    <LayoutMain
      title={textJP.common.please_verify}
      description={textJP.withdraw.notice}
      wrapperClassName='ly_container'
    >
      <Formik
        initialValues={verifyInitialValues}
        validationSchema={verifyValidationSchema}
        onSubmit={verify}
      >
        {(props: FormikProps<VerifyValues>) => {
          return (
            <Form>
              <dl className='bl_form ut_mt14'>
                <FormItems
                  name='authentication_code_hash'
                  label={textJP.common.authentication_code_hash}
                  placeholder={textJP.common.authentication_code_hash}
                  regex={onlyNumberRegex}
                  inputMode='numeric'
                />
              </dl>

              <div className='ut_bg_gray el_txt_s ut_mb20'>
                <div className='ly_container'>
                  <p>{textJP.withdraw.message_1}</p>
                  <p>{textJP.withdraw.message_2}</p>
                  <p>{textJP.withdraw.message_3}</p>
                  <p>{textJP.withdraw.message_4}</p>
                </div>
              </div>

              <div className='el_txt_center ut_mt5'>
                <ErrorResponse errorMess={errorMess} />
                <Button
                  type='submit'
                  variant='contained'
                  className='el_btn_common'
                >
                  {textJP.btn.send}
                </Button>
              </div>

              <div className='el_txt_center ut_mt14'>
                <button
                  type='button'
                  onClick={() => resendCode(props)}
                  className='el_txt_link'
                >
                  {textJP.common.resend_code}
                </button>
              </div>
              <InfoDialog
                open={openInfo}
                setOpen={setOpenInfo}
                title={textJP.common.resend_code_message}
                closeLabel='OK'
              />
            </Form>
          )
        }}
      </Formik>
    </LayoutMain>
  )
}

export default WithdrawVerify
