// include text and number, min 8 letters and max 32 letters, allow special characters
export const passwordRegex =
  /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,32}$/

export const emailRegex =
  /^([a-zA-z0-9+_-]+)(\.[a-zA-z0-9+_-]+)*@([a-z0-9-]+\.)+[a-z]{2,6}$/

export const onlyNumberRegex = /^[0-9]+$/
export const kanjiRegex = /^[一-龥\s]+$/
export const hiraganaRegex = /^[ぁ-ん\s]+$/
export const katakanaRegex = /^[ァ-ンー\s]+$/
export const onlyJapaneseCharacterRegex = /^[一-龥ぁ-んァ-ンー\s]+$/
export const hiraganaAndAlphabetRegex = /^[ぁ-んA-za-z\s]+$/
export const hiraganaAndKatakanaRegex = /^[ぁ-んァ-ンー\s]+$/
export const hiraganaAndKanjiRegex = /^[ぁ-ん一-龥\s]+$/
export const alphanumericHalfWidth = /^[a-zA-Z0-9]+$/

export const MAX_LENGTH_TEXT = 255
export const MAX_LENGTH_TEXTAREA = 2000
export const MAX_LENGTH_PASSWORD = 32
export const MAX_LENGTH_POSTCODE = 8

export const YYYY_MM_DD_unit = 'YYYY年MM月DD日'
export const YYYY_MM_DD = 'YYYY-MM-DD'

export const payjpStyleInput = {
  base: {
    fontFamily: 'Noto Sans JP, sans-serif',
    fontSize: '14px',
    lineHeight: '30px'
  },
  invalid: {
    fontWeight: 500
  }
}
