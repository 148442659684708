import { setLocale } from 'yup'
import texts from 'src/langs/ja'
import textJP from 'src/langs/ja'
import { setFieldLang } from 'src/utils'

setLocale({
  mixed: {
    required: ({ path }) => {
      const pathName = (textJP.common as any)[path]
      const validationMess = textJP.validation_message.required
      let formatMess = setFieldLang(validationMess, pathName)
      return formatMess
    }
  },
  string: {
    matches: () => {
      return texts.validation_message.wrong_format
    },
    email: () => {
      return texts.validation_message.wrong_format
    }
  }
})
