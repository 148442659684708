import { PayloadAction, createSlice } from '@reduxjs/toolkit'

const tokenValue: string = ''

export const deviceToken = createSlice({
  name: 'deviceToken',
  initialState: tokenValue,
  reducers: {
    saveDeviceToken: (state: string, value: PayloadAction<string>) => {
      return value.payload
    },
    clearDeviceToken: () => {
      return ''
    }
  }
})

// Action creators are generated for each case reducer function
export const { saveDeviceToken, clearDeviceToken } = deviceToken.actions

export default deviceToken.reducer
