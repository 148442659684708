// uL10
import { Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import FormItems from 'src/components/FormItems'
import LayoutMain from 'src/layouts/LayoutMain'
import Button from '@mui/material/Button'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import textJP from 'src/langs/ja'
import path from 'src/routers/Path'
import { apiLogin } from 'src/services/AuthService'
import { useDispatch, useSelector } from 'react-redux'
import { AuthFormValues } from 'src/models'
import { RESPONSE_STATUS } from 'src/constants/enum'
import { clearRoute } from 'src/stores/PreviousRoute'
import { authInitialValues } from 'src/helpers/initialValues'
import { loginValidationSchema } from 'src/helpers/schema'
import { clearBlock, setBlock } from 'src/stores/blockUI'
import ErrorResponse from 'src/components/ErrorResponse'
import { RootState } from 'src/stores'
import { saveDataAfterLogin } from 'src/utils'
import TogglePasswordType from 'src/components/TogglePasswordType'

const Login = () => {
  const [typePassword, setTypePassword] = useState<string>('password')
  const [errorMess, setErrorMess] = useState<string>('')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const previousRoute = useSelector((state: RootState) => state.previousRoute)
  const [searchParams] = useSearchParams()
  const error = searchParams.get('error') as string

  useEffect(() => {
    if (error && error === 'invalid_user') {
      setErrorMess(error)
    }
  }, [error])

  const login = async (values: AuthFormValues) => {
    try {
      setErrorMess('')
      dispatch(setBlock())
      const response = await apiLogin(values)
      if (response?.status === RESPONSE_STATUS.SUCCESS) {
        await saveDataAfterLogin(response.data.data.access_token)
        navigate(previousRoute || path.home)
        setTimeout(() => {
          dispatch(clearRoute())
        }, 500)
      }
    } catch (error: any) {
      const message = error?.response?.data?.message
      setErrorMess(message)
    } finally {
      dispatch(clearBlock())
    }
  }

  return (
    <LayoutMain
      title={textJP.common.login_page}
      showHeader={false}
      showHeaderWeb
      containerClassName='ut_bg_lightBlue'
      wrapperClassName='ut_mt45 ut_mb50 ly_container'
    >
      <Formik
        initialValues={authInitialValues}
        validationSchema={loginValidationSchema}
        onSubmit={login}
      >
        {() => {
          return (
            <Form>
              <dl className='bl_form'>
                <FormItems
                  name='email'
                  label={textJP.common.email}
                  placeholder={textJP.common.email}
                  inputMode='email'
                />

                <FormItems
                  name='password'
                  label={textJP.common.password}
                  placeholder={textJP.common.password_notice}
                  type={typePassword}
                >
                  <TogglePasswordType
                    typePassword={typePassword}
                    setTypePassword={setTypePassword}
                  />
                </FormItems>
              </dl>

              <div className='el_txt_right ut_mt20'>
                <Link to={path.forgot_password} className='el_txt_link'>
                  {textJP.login.to_forgot_password}
                </Link>
              </div>

              <div className='el_txt_center ut_mt20'>
                <ErrorResponse errorMess={errorMess} />
                <Button type='submit' className='el_btn_common'>
                  {textJP.btn.login}
                </Button>
              </div>

              <div className='el_txt_center ut_mt14'>
                <Link to={path.register} className='el_txt_link'>
                  {textJP.login.to_register}
                </Link>
              </div>
            </Form>
          )
        }}
      </Formik>
    </LayoutMain>
  )
}

export default Login
