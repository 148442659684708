import logo from 'src/assets/images/logo.png'
import cx from 'classnames'

type HeaderWebProps = {
  className?: string
}

const HeaderWeb = (props: HeaderWebProps) => {
  const { className } = props
  return (
    <header className={cx('ly_container', className)}>
      <h1 className='el_ttl_page mb-0'>
        <img src={logo} alt='PLAMOBI' className='h-auto' />
      </h1>
    </header>
  )
}

export default HeaderWeb
