import axios from 'axios'
import { store } from 'src/stores'
import { apiRefreshToken } from 'src/services/AuthService'
import { saveToken } from 'src/stores/Token'
import { clearLocalStorage, isMobileDevice } from 'src/utils'
import path from 'src/routers/Path'

export const baseApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
})

let isRefreshing = false
let refreshSubscribers: any = []

function onRrefreshed(token: string) {
  refreshSubscribers.map((callback: (value: string) => void) => callback(token))
}

function addRefreshSubscriber(callback: any) {
  refreshSubscribers.push(callback)
}

baseApi.interceptors.request.use(
  config => {
    const token = store?.getState()?.token || ''
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
      config.headers['Content-Type'] = 'application/json;charset=UTF-8'
      config.headers['Accept'] = 'application/json'
    }
    return config
  },
  error => Promise.reject(error)
)

baseApi.interceptors.response.use(
  res => res,
  async err => {
    const originalRequest = err.config

    if (
      err.response &&
      err.response.data.message === 'TOKEN_EXPIRED' &&
      !originalRequest._retry
    ) {
      if (isMobileDevice()) {
        window.location.href = `${window.location.origin}${path.close_web}?type=token-expired`
        return Promise.reject(err)
      }

      if (isRefreshing) {
        return new Promise(resolve => {
          addRefreshSubscriber((token: any) => {
            originalRequest.headers['Authorization'] = 'Bearer ' + token
            resolve(baseApi(originalRequest))
          })
        })
      }

      originalRequest._retry = true
      isRefreshing = true

      return new Promise(async (resolve, reject) => {
        try {
          const response = await apiRefreshToken()
          store.dispatch(saveToken(response.data.data.access_token))
          baseApi.defaults.headers.common[
            'Authorization'
          ] = `Bearer ${response.data.data.access_token}`
          isRefreshing = false
          onRrefreshed(response.data.data.access_token)
          refreshSubscribers = []
          resolve(baseApi(originalRequest))
        } catch (refreshError) {
          isRefreshing = false
          refreshSubscribers = []
          reject(refreshError)
        }
      })
    }

    if (err.response && err.response.data.message === 'INVALID_USER') {
      clearLocalStorage()
      if (!isMobileDevice()) {
        window.location.href = `${window.location.origin}${path.login}?error=invalid_user`
        return Promise.reject(err)
      } else {
        window.location.href = `${window.location.origin}${path.close_web}?type=invalid_user`
      }
    }
    return Promise.reject(err)
  }
)

export default baseApi
