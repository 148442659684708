// uL31
import { Form, Formik, FormikProps } from 'formik'
import { useState } from 'react'
import FormItems from 'src/components/FormItems'
import LayoutMain from 'src/layouts/LayoutMain'
import Button from '@mui/material/Button'
import textJP from 'src/langs/ja'
import { verifyInitialValues } from 'src/helpers/initialValues'
import { verifyValidationSchema } from 'src/helpers/schema'
import ErrorResponse from 'src/components/ErrorResponse'
import { HandleApiProps, VerifyValues } from 'src/models'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { clearBlock, setBlock } from 'src/stores/blockUI'
import { RESPONSE_STATUS } from 'src/constants/enum'
import path from 'src/routers/Path'
import {
  apiChangeEmailResend,
  apiChangeEmailVerify
} from 'src/services/UserService'
import InfoDialog from 'src/components/dialog/InfoDialog'
import { handleNavigateApp } from 'src/utils'
import { saveProfile } from 'src/stores/Profile'
import { RootState } from 'src/stores'
import { onlyNumberRegex } from 'src/constants'

const ChangeEmailVerify = () => {
  const [errorMess, setErrorMess] = useState<string>('')
  const [openInfo, setOpenInfo] = useState<boolean>(false)
  const profile = useSelector((state: RootState) => state.profile)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleAPI = async ({
    apiCall,
    onSuccess,
    onError,
    onFinally
  }: HandleApiProps) => {
    try {
      setErrorMess('')
      dispatch(setBlock())
      const repsonse = await apiCall
      if (repsonse?.status === RESPONSE_STATUS.SUCCESS) {
        onSuccess?.(repsonse?.data?.data)
      }
    } catch (error: any) {
      const message = error?.response?.data?.message
      setErrorMess(message)
      onError?.()
    } finally {
      onFinally?.()
      dispatch(clearBlock())
    }
  }

  const resendCode = async (formikProps: FormikProps<VerifyValues>) => {
    handleAPI({
      apiCall: apiChangeEmailResend(),
      onSuccess: () => {
        formikProps.setFieldValue('authentication_code_hash', '', false)
        setOpenInfo(true)
      }
    })
  }

  const verify = async (values: VerifyValues) => {
    const payload: VerifyValues = {
      authentication_code_hash: values.authentication_code_hash
    }
    handleAPI({
      apiCall: apiChangeEmailVerify(payload),
      onSuccess: (data: any) => {
        dispatch(saveProfile({ ...profile, email: data.new_email }))
        handleNavigateApp({
          pathMobile: path.close_web,
          pathBrowser: path.profile,
          navigate
        })
      }
    })
  }

  return (
    <LayoutMain
      title={textJP.common.please_verify}
      description={textJP.register_verify.notice}
      wrapperClassName='ly_container'
    >
      <Formik
        initialValues={verifyInitialValues}
        validationSchema={verifyValidationSchema}
        onSubmit={verify}
      >
        {(props: FormikProps<VerifyValues>) => {
          return (
            <Form>
              <dl className='bl_form ut_mt14'>
                <FormItems
                  name='authentication_code_hash'
                  label={textJP.common.authentication_code_hash}
                  placeholder={textJP.common.authentication_code_hash}
                  regex={onlyNumberRegex}
                  inputMode='numeric'
                />
              </dl>

              <div className='el_txt_center ut_mt20'>
                <ErrorResponse errorMess={errorMess} />
                <Button
                  type='submit'
                  variant='contained'
                  className='el_btn_common'
                >
                  {textJP.btn.send}
                </Button>
              </div>

              <div className='el_txt_center ut_mt14'>
                <button
                  type='button'
                  onClick={() => resendCode(props)}
                  className='el_txt_link'
                >
                  {textJP.common.resend_code}
                </button>
              </div>

              <InfoDialog
                open={openInfo}
                setOpen={setOpenInfo}
                title={textJP.common.resend_code_message}
                closeLabel='OK'
              />
            </Form>
          )
        }}
      </Formik>
    </LayoutMain>
  )
}

export default ChangeEmailVerify
