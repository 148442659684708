import { AxiosPromise } from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { APPLICATION_ERROR_TYPE, RESPONSE_STATUS } from 'src/constants/enum'

type FetchDetailProps = {
  endpoint: () => AxiosPromise
}

const useFetchDetail = (props: FetchDetailProps) => {
  const { endpoint } = props
  const [detail, setDetail] = useState<any>(null)
  const [errorType, setErrorType] = useState<APPLICATION_ERROR_TYPE>()

  const fetchData = useCallback(async () => {
    try {
      const res = await endpoint()
      if (res?.status === RESPONSE_STATUS.SUCCESS) {
        setDetail(res.data.data)
      }
    } catch (err: any) {
      if (!err.response) {
        return setErrorType(APPLICATION_ERROR_TYPE.NETWORK_ERROR)
      }
      if (err.response.status === RESPONSE_STATUS.NOT_FOUND) {
        return setErrorType(APPLICATION_ERROR_TYPE.NOT_FOUND)
      }
      if (err.response.status === RESPONSE_STATUS.INTERNAL) {
        return setErrorType(APPLICATION_ERROR_TYPE.SYSTEM_ERROR)
      }
    }
  }, [endpoint])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return { detail, errorType }
}

export default useFetchDetail
