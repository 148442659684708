import { PayloadAction, createSlice } from '@reduxjs/toolkit'

const initialState: string = ''

export const previousRoute = createSlice({
  name: 'previousRoute',
  initialState,
  reducers: {
    saveRoute: (state: string, value: PayloadAction<string>) => {
      return value.payload
    },
    clearRoute: () => {
      return ''
    }
  }
})

// Action creators are generated for each case reducer function
export const { saveRoute, clearRoute } = previousRoute.actions

export default previousRoute.reducer
