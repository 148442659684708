import {
  AuthFormValues,
  ChangePasswordValues,
  ContactValues,
  ForgotPasswordValues,
  ProfileValues,
  VehicleCreateValues,
  VerifyValues
} from 'src/models'

export const authInitialValues: AuthFormValues = {
  email: '',
  password: ''
}

export const verifyInitialValues: VerifyValues = {
  authentication_code_hash: ''
}

export const resetPasswordInitialValues: VerifyValues = {
  authentication_code_hash: '',
  new_password: ''
}

export const forgotPassInitialValues: ForgotPasswordValues = {
  email: ''
}

export const changePasswordInitialValues: ChangePasswordValues = {
  new_password: ''
}

export const getChangeEmailInitialValues = (email: string) => {
  return {
    current_email: email,
    email_temp: ''
  }
}

export const vehicleCreateInitialValues: VehicleCreateValues = {
  place_name: '',
  classification_number: '',
  serial_number: '',
  car_number_hiragana: ''
}

export const profileInitialValues: ProfileValues = {
  name_mei_kana: '',
  name_sei_kana: '',
  name_mei: '',
  name_sei: '',
  postal_code: '',
  prefecture_code: '',
  address1: '',
  address2: '',
  phone_number: ''
}

export const contactInitialValues: ContactValues = {
  name: '',
  email: '',
  content: ''
}
