import iconClose from 'src/assets/images/icon-close.svg'
import cx from 'classnames'
import RenderIf from '../RenderIf'
import { handleNavigateApp, isMobileDevice } from 'src/utils'
import { useNavigate } from 'react-router-dom'
import path from 'src/routers/Path'

type HeaderProps = {
  title?: string
  showBackBtn?: boolean
  onBack?: () => void
}

const Header = (props: HeaderProps) => {
  const { title, showBackBtn, onBack } = props
  const navigate = useNavigate()

  return (
    <RenderIf isTrue={!!title}>
      <header className='bl_pageTitle'>
        {/* close button */}
        <RenderIf isTrue={isMobileDevice()}>
          <button
            type='button'
            onClick={() =>
              handleNavigateApp({
                pathMobile: path.close_web,
                navigate
              })
            }
            className='close-btn'
          >
            <img src={iconClose} alt='' />
          </button>
        </RenderIf>
        {/* back button */}
        <RenderIf isTrue={showBackBtn as boolean}>
          <button
            onClick={() => onBack && onBack()}
            type='button'
            className='el_btn_circle back-btn'
          >
            <span className='el_icm_arrowL'></span>
          </button>
        </RenderIf>
        <h1
          style={{ marginBottom: '2px' }}
          className={cx('el_ttl_page')}
          dangerouslySetInnerHTML={{ __html: title as string }}
        ></h1>
        <span className='el_ttl_page_en mb-3'>PLAMOBI</span>
      </header>
    </RenderIf>
  )
}

export default Header
