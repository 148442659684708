import dayjs from 'dayjs'
import { NavigateFunction, To } from 'react-router-dom'
import { YYYY_MM_DD_unit } from 'src/constants'
import { CREDIT_BRAND, LOCAL_STORAGE_KEYS } from 'src/constants/enum'
import { NotificationValues, PaymentInfoValues } from 'src/models'
import { apiGetPaymentMethod, apiGetProfile } from 'src/services/UserService'
import { store } from 'src/stores'
import { clearDeviceToken } from 'src/stores/DeviceToken'
import { clearNotification } from 'src/stores/NewNotification'
import { clearPrefectureData } from 'src/stores/Prefecture'
import { clearRoute } from 'src/stores/PreviousRoute'
import { clearProfile, saveProfile } from 'src/stores/Profile'
import { clearToken, saveToken } from 'src/stores/Token'

export const handleNavigateApp = ({
  pathMobile,
  pathBrowser,
  navigate,
  onCallback,
  queryParams
}: {
  pathMobile: string
  pathBrowser?: To
  navigate?: NavigateFunction
  onCallback?: () => void
  queryParams?: Record<string, string>
}) => {
  const buildQueryString = (
    params?: Record<string, string | undefined>
  ): string => {
    if (!params) return ''

    const filteredParams = Object.entries(params)
      .filter(([_, value]) => value !== undefined && value !== '') // Filter out undefined or empty values
      .reduce((acc, [key, value]) => {
        acc[key] = value as string
        return acc
      }, {} as Record<string, string>)

    return Object.keys(filteredParams).length > 0
      ? '?' + new URLSearchParams(filteredParams).toString()
      : ''
  }

  const queryString = buildQueryString(queryParams)
  const url = `${window.location.origin}${pathMobile}${queryString}`

  if (isMobileDevice()) {
    window.location.href = url
  } else if (pathBrowser && navigate) {
    navigate(pathBrowser)
  } else if (onCallback) {
    onCallback()
  }
}

export const formatCurrency = (
  value: string | number | undefined | null
): string => {
  const numericValue = Number(value)

  if (isNaN(numericValue) || numericValue === 0) {
    return '0'
  }

  return numericValue.toLocaleString('ja-JP')
}

export const filterDuplicateItemList = <T extends Record<string, any>>(
  list: T[],
  field: keyof T = 'id'
): T[] => {
  const seen = new Set() // Create a Set to keep track of unique values
  return list.filter(element => {
    const value = element[field]
    if (seen.has(value)) {
      return false
    }
    seen.add(value)
    return true
  })
}

export const loadScript = (
  src: string,
  onLoad: () => void,
  onError: () => void
) => {
  const script = document.createElement('script')
  script.src = src
  script.async = true
  script.onload = onLoad
  script.onerror = onError
  document.body.appendChild(script)
}

export const getInitCardNumberDetail = (
  last4: string,
  brand: CREDIT_BRAND | string
) => {
  switch (brand) {
    case CREDIT_BRAND.MASTERCARD:
    case CREDIT_BRAND.VISA:
    case CREDIT_BRAND.JCB:
    case CREDIT_BRAND.DISCOVER:
      return `**** **** **** ${last4}`
    case CREDIT_BRAND.AMERICAN_EXPRESS:
      return `**** ****** *${last4}`
    case CREDIT_BRAND.DINERS_CLUB:
      return `**** **** **${last4.slice(0, 2)} ${last4.slice(2)}`
    default:
      return ''
  }
}

export const isMobileDevice = () => {
  if (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
      navigator.userAgent
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\\-(n|u)|c55\/|capi|ccwa|cdm\\-|cell|chtm|cldc|cmd\\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\\-s|devi|dica|dmob|do(c|p)o|ds(12|\\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\\-|_)|g1 u|g560|gene|gf\\-5|g\\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\\-(m|p|t)|hei\\-|hi(pt|ta)|hp( i|ip)|hs\\-c|ht(c(\\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\\-(20|go|ma)|i230|iac( |\\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\\-[a-w])|libw|lynx|m1\\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\\-2|po(ck|rt|se)|prox|psio|pt\\-g|qa\\-a|qc(07|12|21|32|60|\\-[2-7]|i\\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\\-|oo|p\\-)|sdk\/|se(c(\\-|0|1)|47|mc|nd|ri)|sgh\\-|shar|sie(\\-|m)|sk\\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\\-|v\\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\\-|tdg\\-|tel(i|m)|tim\\-|t\\-mo|to(pl|sh)|ts(70|m\\-|m3|m5)|tx\\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\\-|your|zeto|zte\\-/i.test(
      navigator.userAgent.substr(0, 4)
    )
  ) {
    return true
  }
  return false
}

export const hasAnyValueObject = <T extends Record<string, any>>(
  object: T,
  excludeArray?: Array<keyof T>
): boolean => {
  if (!object) {
    return false
  }

  if (excludeArray && excludeArray.length > 0) {
    const result = Object.fromEntries(
      Object.entries(object).filter(([key]) => !excludeArray.includes(key))
    )
    return Object.values(result).some(value => value)
  }

  return Object.values(object).some(value => value)
}

export const saveDataAfterLogin = async (token: any) => {
  store.dispatch(saveToken(token))
  const [profileRes, paymentInfoRes] = await Promise.all([
    apiGetProfile(),
    apiGetPaymentMethod()
  ])

  for (let key in profileRes.data.data) {
    if (!profileRes.data.data[key]) {
      profileRes.data.data[key] = ''
    }
  }
  store.dispatch(saveProfile(profileRes.data.data))

  let payload: PaymentInfoValues | null = null

  if (paymentInfoRes?.data?.data) {
    const {
      last4 = '',
      exp_month = '',
      exp_year = '',
      name = '',
      brand = ''
    } = paymentInfoRes?.data?.data || {}
    const expiry_year = exp_year.toString().slice(-2) || ''

    payload = {
      last4,
      exp_month,
      exp_year: expiry_year,
      name,
      brand
    }
  }

  localStorage.setItem(LOCAL_STORAGE_KEYS.PAYMENT_INFO, JSON.stringify(payload))
}

export const clearLocalStorage = () => {
  Object.values(LOCAL_STORAGE_KEYS)
    .filter(
      (key: string) => key !== LOCAL_STORAGE_KEYS.READ_NOTIFY_WITHOUT_LOGIN
    )
    .forEach((key: string) => {
      localStorage.removeItem(key)
    })

  store.dispatch(clearProfile())
  store.dispatch(clearRoute())
  store.dispatch(clearPrefectureData())
  store.dispatch(clearToken())
  store.dispatch(clearDeviceToken())
  store.dispatch(clearNotification())
}

export const getNotifyPublishDate = (value?: NotificationValues) => {
  if (value?.posting_date_and_time) {
    return dayjs(value.posting_date_and_time).format(YYYY_MM_DD_unit)
  }
  if (value?.published_date_and_time) {
    return dayjs(value.published_date_and_time).format(YYYY_MM_DD_unit)
  }
  return null
}

export const setFieldLang = (
  validationMess: string,
  pathName: string,
  fieldToReplace: string = '{field}'
) => {
  validationMess = validationMess.replace(fieldToReplace, pathName)
  return validationMess
}

export const replaceNavigationTag = (parentName: string) => {
  const parentElement = document.querySelector(parentName)
  if (parentElement) {
    const listATag = parentElement.querySelectorAll('a')
    listATag.forEach(function (anchor) {
      const url = anchor.href
      const div = document.createElement('div')

      div.innerText = anchor.textContent || ''
      div.classList.add('a-tag')

      div.onclick = function () {
        window.open(url, '_blank')
      }

      if (anchor.parentNode) {
        anchor.parentNode.replaceChild(div, anchor)
      }
    })
  }
}
