import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ProfileValues } from 'src/models'

const detail: ProfileValues = {
  email: '',
  name_mei_kana: '',
  name_sei_kana: '',
  name_mei: '',
  name_sei: '',
  postal_code: '',
  prefecture_code: '',
  address1: '',
  address2: '',
  phone_number: '',
  created_at: '',
  updated_at: '',
  is_verified: 0
}

export const profile = createSlice({
  name: 'profile',
  initialState: detail,
  reducers: {
    saveProfile: (
      state: ProfileValues,
      value: PayloadAction<ProfileValues>
    ) => {
      return value.payload
    },
    clearProfile: () => {
      return { ...detail }
    }
  }
})

// Action creators are generated for each case reducer function
export const { saveProfile, clearProfile } = profile.actions

export default profile.reducer
