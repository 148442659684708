import { ErrorMessage, Field, FieldProps } from 'formik'
import cx from 'classnames'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { OutlinedInput } from '@mui/material'
import { MAX_LENGTH_TEXT, MAX_LENGTH_TEXTAREA } from 'src/constants'
import RenderIf from 'src/components/RenderIf'

export const TYPE_INPUT = {
  SELECT: 'SELECT',
  RADIO: 'RADIO',
  CHECKBOX: 'CHECKBOX',
  TEXT: 'TEXT',
  NUMBER: 'NUMBER',
  TEXT_AREA: 'TEXT_AREA',
  PHONE: 'PHONE'
}

type FormItemsProps = {
  children?: JSX.Element
  label?: string
  name: string
  wrapperClassName?: string
  labelClassName?: string
  inputClassName?: string
  selectClassName?: string
  placeholder?: string
  typeInput?: string
  type?: string
  required?: boolean
  onChange?: (event?: any) => void
  onBlur?: (event?: any) => void
  regex?: RegExp
  description?: string
  optionsList?: Array<any>
  readonly?: boolean
  descriptionBelow?: boolean
  maxLength?: number
  disabled?: boolean
  loading?: boolean
  showIcon?: boolean
  inputMode?:
    | 'none'
    | 'text'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search'
    | undefined
}

const FormItems = (props: FormItemsProps) => {
  const {
    children,
    name,
    wrapperClassName,
    labelClassName,
    inputClassName,
    selectClassName,
    placeholder,
    label,
    type = 'text',
    typeInput = TYPE_INPUT.TEXT,
    required,
    onChange,
    onBlur,
    regex,
    description,
    optionsList,
    readonly,
    descriptionBelow,
    maxLength,
    disabled,
    loading,
    showIcon,
    inputMode
  } = props

  function TextComponent(context: FieldProps) {
    const { field, form } = context
    const { setFieldValue, handleBlur } = form
    const handleChange = (event: any) => {
      const { value = '' } = event.target || {}
      let newValue = value

      if (regex) {
        if (!value || regex.test(value)) {
          // Matches regex
          setFieldValue(name, value)
        } else {
          // Does not match regex
          newValue = field.value
          setFieldValue(name, field.value)
        }
      } else {
        // No regex provided
        setFieldValue(name, value)
      }

      onChange?.(newValue)
    }

    const handleOnBlur = (event: any) => {
      handleBlur?.(event)
      onBlur?.(event)
    }

    return (
      <div className='relative'>
        <input
          maxLength={maxLength || MAX_LENGTH_TEXT}
          {...field}
          className={cx('el_input', inputClassName)}
          id={name}
          onChange={handleChange}
          onBlur={handleOnBlur}
          placeholder={placeholder}
          type={type}
          readOnly={readonly}
          disabled={disabled || loading}
          inputMode={inputMode}
        />
        <RenderIf isTrue={(showIcon && loading) as boolean}>
          <div className='loader-input-container'>
            <span className='loader-input'></span>
          </div>
        </RenderIf>
      </div>
    )
  }

  function TextAreaComponent(context: FieldProps) {
    const { field, form } = context
    const { setFieldValue, handleBlur } = form
    const handleChange = (event: any) => {
      const { value = '' } = event.target || {}

      if (regex) {
        if (!value || regex.test(value)) {
          // Matches regex
          setFieldValue(name, value)
        } else {
          // Does not match regex
          setFieldValue(name, field.value)
        }
      } else {
        // No regex provided
        setFieldValue(name, value)
      }

      onChange?.(event)
    }

    const handleOnBlur = (event: any) => {
      handleBlur?.(event)
      onBlur?.(event)
    }

    return (
      <textarea
        maxLength={maxLength || MAX_LENGTH_TEXTAREA}
        readOnly={readonly}
        {...field}
        className={cx('el_input', inputClassName)}
        id={name}
        onChange={handleChange}
        onBlur={handleOnBlur}
        placeholder={placeholder}
        disabled={disabled || loading}
      />
    )
  }

  function SelectComponent(context: FieldProps) {
    const { field, form } = context
    const { setFieldValue, handleBlur } = form
    const handleSelect = (event: any) => {
      const { value = '' } = event.target || {}
      setFieldValue(name, value)
      onChange?.(event)
    }

    return (
      <Select
        {...field}
        value={field.value}
        inputProps={{
          name: field?.name
        }}
        input={
          <OutlinedInput classes={{ notchedOutline: 'form-item-select' }} />
        }
        classes={{
          root: 'h-30px'
        }}
        MenuProps={{
          classes: { paper: 'max-h-208px' }
        }}
        onChange={handleSelect}
        onBlur={handleBlur}
        className={selectClassName}
        displayEmpty
        renderValue={value => {
          if (value) {
            return <div className='el_txt_rg truncate'>{value}</div>
          }
          return <div className='el_txt_rg text-gray_1'>{placeholder}</div>
        }}
        disabled={disabled || loading}
      >
        {optionsList?.map(({ label, value }) => (
          <MenuItem key={value} value={value}>
            <div className='truncate el_txt_rg'>{label}</div>
          </MenuItem>
        ))}
      </Select>
    )
  }

  function Selector(context: FieldProps, typeInput: string) {
    switch (typeInput) {
      case TYPE_INPUT.TEXT:
        return TextComponent(context)
      case TYPE_INPUT.SELECT:
        return SelectComponent(context)
      case TYPE_INPUT.TEXT_AREA:
        return TextAreaComponent(context)
      default:
        break
    }
  }

  return (
    <div className='bl_form_body'>
      <div className={cx('bl_form', wrapperClassName)}>
        <RenderIf isTrue={!!label}>
          <div className='bl_flex align_center'>
            <label
              className={cx(labelClassName, required && 'required')}
              htmlFor={name}
            >
              {label}
            </label>
            <RenderIf isTrue={!descriptionBelow && !!description}>
              <p className={cx('el_txt_xs', required ? 'ml-6' : 'ut_ml8')}>
                {description}
              </p>
            </RenderIf>
          </div>
        </RenderIf>

        <RenderIf isTrue={!!descriptionBelow && !!description}>
          <p className='el_txt_xs'>{description}</p>
        </RenderIf>

        <Field name={name}>
          {(props: FieldProps) => {
            return Selector(props, typeInput)
          }}
        </Field>
      </div>
      {children}
      <ErrorMessage
        component='span'
        className='el_txt_xs el_txt_red'
        name={name}
      />
    </div>
  )
}

export default FormItems
