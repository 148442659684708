import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { OptionValues } from 'src/models'

const initialState: OptionValues[] = []

export const prefecture = createSlice({
  name: 'prefecture',
  initialState,
  reducers: {
    savePrefectureData: (
      state: OptionValues[],
      value: PayloadAction<OptionValues[]>
    ) => {
      return value.payload
    },
    clearPrefectureData: () => {
      return []
    }
  }
})

// Action creators are generated for each case reducer function
export const { savePrefectureData, clearPrefectureData } = prefecture.actions

export default prefecture.reducer
