import { useCallback, useEffect } from 'react'

type InfiniteScrollProps = {
  scrollableRef: any
  hasMore: boolean
  onCallback: () => void
}

const useInfiniteScroll = (props: InfiniteScrollProps) => {
  const { scrollableRef, hasMore, onCallback } = props
  const handleScroll = useCallback(
    (event: any) => {
      if (hasMore) {
        const { scrollTop, scrollHeight, clientHeight } = event.target
        const isBottom =
          Math.round(scrollTop + clientHeight) >= Math.round(scrollHeight)

        if (isBottom) {
          onCallback()
        }
      }
    },
    [hasMore, onCallback]
  )

  useEffect(() => {
    const element = scrollableRef.current
    element?.addEventListener('scroll', handleScroll)
    return () => element?.removeEventListener('scroll', handleScroll)
  }, [handleScroll, scrollableRef])
}

export default useInfiniteScroll
