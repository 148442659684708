// uL30
import { Form, Formik } from 'formik'
import { useState } from 'react'
import FormItems from 'src/components/FormItems'
import LayoutMain from 'src/layouts/LayoutMain'
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'
import textJP from 'src/langs/ja'
import path from 'src/routers/Path'
import { authInitialValues } from 'src/helpers/initialValues'
import { registerValidationSchema } from 'src/helpers/schema'
import { AuthFormValues } from 'src/models'
import { useDispatch } from 'react-redux'
import { apiRegister } from 'src/services/AuthService'
import { RESPONSE_STATUS } from 'src/constants/enum'
import { clearBlock, setBlock } from 'src/stores/blockUI'
import ErrorResponse from 'src/components/ErrorResponse'
import { handleNavigateApp } from 'src/utils'
import TogglePasswordType from 'src/components/TogglePasswordType'
import { MAX_LENGTH_PASSWORD } from 'src/constants'

const Register = () => {
  const [typePassword, setTypePassword] = useState<string>('password')
  const [acceptCondition, setAcceptCondition] = useState<boolean>(false)
  const [errorMess, setErrorMess] = useState<string>('')
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleAcceptCondition = () => {
    setAcceptCondition(!acceptCondition)
  }

  const register = async (values: AuthFormValues) => {
    try {
      setErrorMess('')
      dispatch(setBlock())
      const newPath = `${path.register_verify}?email=${values.email}`
      const payload: AuthFormValues = {
        ...values
      }
      const repsonse = await apiRegister(payload)
      if (repsonse?.status === RESPONSE_STATUS.CREATED) {
        navigate(newPath)
      }
    } catch (error: any) {
      const message = error?.response?.data?.message
      setErrorMess(message)
    } finally {
      dispatch(clearBlock())
    }
  }

  return (
    <LayoutMain
      title={textJP.register.title}
      description={textJP.register.notice}
      wrapperClassName='ly_container'
    >
      <>
        <div className='el_txt_right'>
          <span className='el_txt_red el_txt_bold400'>
            {textJP.common.notice_required}
          </span>
        </div>

        <Formik
          initialValues={authInitialValues}
          validationSchema={registerValidationSchema}
          onSubmit={register}
        >
          {() => {
            return (
              <Form>
                <dl className='bl_form ut_mt14'>
                  <FormItems
                    required
                    name='email'
                    label={textJP.common.email}
                    placeholder={textJP.common.email}
                    inputMode='email'
                  >
                    <>
                      <p className='el_txt_s ut_mt5'>
                        {textJP.register.email_notice_1}
                      </p>
                      <p className='el_txt_s ut_mt5'>
                        {textJP.register.email_notice_2}
                      </p>
                    </>
                  </FormItems>

                  <FormItems
                    required
                    name='password'
                    label={textJP.common.password}
                    placeholder={textJP.common.password_notice}
                    type={typePassword}
                    maxLength={MAX_LENGTH_PASSWORD}
                  >
                    <TogglePasswordType
                      typePassword={typePassword}
                      setTypePassword={setTypePassword}
                    >
                      <>
                        <p className='el_txt_s ut_mt5'>
                          {textJP.register.password_notice_1}
                        </p>
                        <p className='el_txt_s ut_mt5'>
                          {textJP.register.password_notice_2}
                        </p>
                      </>
                    </TogglePasswordType>
                  </FormItems>
                </dl>

                <div className='el_txt_center ut_mt10'>
                  <Checkbox
                    className='el_checkbox'
                    checked={acceptCondition}
                    onChange={handleAcceptCondition}
                    id='accept-conditon'
                    sx={{
                      '& .MuiSvgIcon-root': { fontSize: 18 }
                    }}
                  />
                  <label htmlFor='accept-conditon' className='el_label'>
                    <span>
                      <button
                        type='button'
                        className='el_txt_link'
                        onClick={() =>
                          handleNavigateApp({
                            pathMobile: path.term_of_use,
                            pathBrowser: path.term_of_use,
                            navigate
                          })
                        }
                      >
                        {textJP.my_page.term_of_use}
                      </button>
                      、
                      <button
                        type='button'
                        className='el_txt_link'
                        onClick={() =>
                          handleNavigateApp({
                            pathMobile: path.privacy_policy,
                            pathBrowser: path.privacy_policy,
                            navigate
                          })
                        }
                      >
                        {textJP.my_page.privacy_policy}
                      </button>
                    </span>
                    {textJP.register.accept_condition_2}
                  </label>
                </div>

                <div className='el_txt_center ut_mt20'>
                  <ErrorResponse errorMess={errorMess} />
                  <Button
                    disabled={!acceptCondition}
                    type='submit'
                    variant='contained'
                    className='el_btn_common'
                  >
                    {textJP.btn.register}
                  </Button>
                </div>

                <div className='el_txt_center ut_mt14'>
                  <button
                    type='button'
                    onClick={() =>
                      handleNavigateApp({
                        pathMobile: path.login_app,
                        pathBrowser: path.login,
                        navigate
                      })
                    }
                    className='el_txt_link'
                  >
                    {textJP.register.to_login}
                  </button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </>
    </LayoutMain>
  )
}

export default Register
