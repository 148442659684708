import { Button, Checkbox } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import FormItems, { TYPE_INPUT } from 'src/components/FormItems'
import textJP from 'src/langs/ja'
import path from 'src/routers/Path'
import { handleNavigateApp } from 'src/utils'

type Props = {
  acceptCondition: boolean
  setAcceptCondition: (state: boolean) => void
}

const Step1 = (props: Props) => {
  const { acceptCondition, setAcceptCondition } = props
  const navigate = useNavigate()
  return (
    <>
      <div className='el_txt_right'>
        <p className='el_txt_red el_txt_bold400'>
          {textJP.common.notice_required}
        </p>
      </div>

      <div className='bl_form'>
        <FormItems
          required
          name='name'
          label={textJP.common.name}
          placeholder={textJP.common.full_name}
        ></FormItems>

        <FormItems
          required
          name='email'
          label={textJP.common.email}
          placeholder={textJP.common.email}
          inputMode='email'
        ></FormItems>

        <FormItems
          typeInput={TYPE_INPUT.TEXT_AREA}
          required
          name='content'
          label={textJP.common.content}
          placeholder={textJP.common.content}
        ></FormItems>
      </div>

      <div className='bl_flex justify_center align_start'>
        <Checkbox
          className='el_checkbox'
          checked={acceptCondition}
          onChange={() => setAcceptCondition(!acceptCondition)}
          id='accept-conditon'
          sx={{
            '& .MuiSvgIcon-root': { fontSize: 18 }
          }}
        />
        <label htmlFor='accept-conditon' className='el_label'>
          <span>
            <button
              type='button'
              className='el_txt_link'
              onClick={() =>
                handleNavigateApp({
                  pathMobile: path.term_of_use,
                  pathBrowser: path.term_of_use,
                  navigate
                })
              }
            >
              {textJP.my_page.term_of_use}
            </button>
            、
            <button
              type='button'
              className='el_txt_link'
              onClick={() =>
                handleNavigateApp({
                  pathMobile: path.privacy_policy,
                  pathBrowser: path.privacy_policy,
                  navigate
                })
              }
            >
              {textJP.my_page.privacy_policy}
            </button>
          </span>
          {textJP.register.accept_condition_2}
        </label>
      </div>

      <div className='el_txt_center ut_mt20'>
        <Button
          disabled={!acceptCondition}
          type='submit'
          variant='contained'
          className='el_btn_common'
        >
          {textJP.btn.check_contact}
        </Button>
      </div>
    </>
  )
}

export default Step1
