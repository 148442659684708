import { createSlice } from '@reduxjs/toolkit'

const initialState: boolean = false

export const initPayJP = createSlice({
  name: 'initPayJP',
  initialState,
  reducers: {
    setInitPayJP: (state: boolean) => {
      return true
    },
    clearPayJP: () => {
      return false
    }
  }
})

// Action creators are generated for each case reducer function
export const { setInitPayJP, clearPayJP } = initPayJP.actions

export default initPayJP.reducer
