import textJP from 'src/langs/ja'
import RenderIf from 'src/components/RenderIf'

type ErrorResponseProps = {
  errorMess: string
}

const ErrorResponse = ({ errorMess }: ErrorResponseProps) => {
  return (
    <RenderIf isTrue={!!errorMess}>
      <p
        className='el_txt_red ut_mb5'
        dangerouslySetInnerHTML={{ __html: (textJP.common as any)[errorMess] }}
      ></p>
    </RenderIf>
  )
}

export default ErrorResponse
