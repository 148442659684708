import { NOTIFICATION_PRIORITY, NOTIFICATION_TYPE } from 'src/constants/enum'
import textJP from 'src/langs/ja'
import { NotificationValues } from 'src/models'
import RenderIf from 'src/components/RenderIf'
import cx from 'classnames'
import { useCallback, useEffect } from 'react'
import { apiReadNotification } from 'src/services/Notification'
import {
  getNotifyPublishDate,
  isMobileDevice,
  replaceNavigationTag
} from 'src/utils'
import { LOCAL_STORAGE_KEYS } from 'src/constants/enum'
import { useSelector } from 'react-redux'
import { RootState } from 'src/stores'

type NotificationDetailDataProps = {
  detail?: NotificationValues
  announcement_type: NOTIFICATION_TYPE
}
const NotificationDetailData = (props: NotificationDetailDataProps) => {
  const { detail, announcement_type } = props
  const token = useSelector((state: RootState) => state.token)

  const markRedNotification = useCallback(async () => {
    if (detail?.id) {
      if (token) {
        await apiReadNotification({
          id: detail?.id as number,
          announcement_type
        })
        localStorage.setItem(
          LOCAL_STORAGE_KEYS.READ_NOTIFY,
          JSON.stringify({ id: detail?.id })
        )
      } else {
        const payload = {
          id: detail?.id,
          type: announcement_type
        }
        const jsonData = localStorage.getItem(
          LOCAL_STORAGE_KEYS.READ_NOTIFY_WITHOUT_LOGIN
        )
        let readNotificationWithoutLogin = jsonData ? JSON.parse(jsonData) : []
        const exists = readNotificationWithoutLogin.some(
          (notification: any) => notification.id === payload.id
        )
        if (!exists) {
          readNotificationWithoutLogin.push(payload)
        }
        localStorage.setItem(
          LOCAL_STORAGE_KEYS.READ_NOTIFY_WITHOUT_LOGIN,
          JSON.stringify(readNotificationWithoutLogin)
        )
      }
      replaceNavigationTag('.editor-content')
    }
  }, [detail?.id, announcement_type, token])

  useEffect(() => {
    markRedNotification()
  }, [markRedNotification])

  return (
    <RenderIf isTrue={!!detail?.id}>
      <div className='ly_container'>
        <div
          className={cx('notification-detail-content', {
            mobile: isMobileDevice()
          })}
        >
          <h2 className='el_txt_md break-word'>{detail?.title}</h2>
          <div className='ut_mt5'>
            <p className='el_txt_s ut_inlineBlock'>
              {getNotifyPublishDate(detail)}
            </p>
            <RenderIf
              isTrue={detail?.priority === NOTIFICATION_PRIORITY.IMPORTANT}
            >
              <p className='el_txt_s ut_inlineBlock ut_ml12'>
                {textJP.notification.important}
              </p>
            </RenderIf>
          </div>

          <div className='bl_editor ut_mt20'>
            <div className='h-full overflow-auto'>
              <div
                dangerouslySetInnerHTML={{
                  __html: (detail?.content || detail?.contents) as any
                }}
                className='editor-content mr-2'
              ></div>
            </div>
          </div>
        </div>
      </div>
    </RenderIf>
  )
}

export default NotificationDetailData
