import { DISPLAY_TYPE } from 'src/constants/enum'
import { ContactValues } from 'src/models'
import { baseApi } from 'src/services/base'

const prefix = '/information'

export const apiDisplayDataByType = (params: { type: DISPLAY_TYPE }) => {
  return baseApi.get(`${prefix}/display`, { params })
}

export const apiGetDataFAQ = (params: { page: number }) => {
  return baseApi.get(`${prefix}/questions-answers`, { params })
}

export const apiContactStore = (payload: ContactValues) => {
  return baseApi.post(`${prefix}/contact/create`, payload)
}