import { PayloadAction, createSlice } from '@reduxjs/toolkit'

const tokenValue: string = ''

export const token = createSlice({
  name: 'token',
  initialState: tokenValue,
  reducers: {
    saveToken: (state: string, value: PayloadAction<string>) => {
      return value.payload
    },
    clearToken: () => {
      return ''
    }
  }
})

// Action creators are generated for each case reducer function
export const { saveToken, clearToken } = token.actions

export default token.reducer
