// uP30
import LayoutMain from 'src/layouts/LayoutMain'
import textJP from 'src/langs/ja'
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import ErrorResponse from 'src/components/ErrorResponse'
import { Button } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { clearBlock, setBlock } from 'src/stores/blockUI'
import listCredit from 'src/assets/images/list-credit.png'
import {
  MAX_LENGTH_TEXT,
  hiraganaAndAlphabetRegex,
  payjpStyleInput
} from 'src/constants'
import { RootState } from 'src/stores'
import { apiCreateOrUpdatePaymentMethod } from 'src/services/UserService'
import { useNavigate } from 'react-router-dom'
import path from 'src/routers/Path'
import { PaymentInfoValues } from 'src/models'
import { handleNavigateApp, setFieldLang } from 'src/utils'
import { LOCAL_STORAGE_KEYS, RESPONSE_STATUS } from 'src/constants/enum'

const PaymentInformationUpdate = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const paymentInfoStorage = localStorage.getItem(
    LOCAL_STORAGE_KEYS.PAYMENT_INFO
  )
  const paymentInfo = paymentInfoStorage ? JSON.parse(paymentInfoStorage) : null
  const [errorMess, setErrorMess] = useState<string>('')
  const [elements, setElements] = useState<any>()
  const [cardName, setCardName] = useState(paymentInfo?.name || '')
  const cardNumber = useRef<HTMLDivElement>(null)
  const cardCvc = useRef<HTMLDivElement>(null)
  const cardExpiry = useRef<HTMLDivElement>(null)
  const nameCard = useRef<HTMLDivElement>(null)
  const [isFormValid, setIsFormValid] = useState<boolean>(false)
  const initPayJP = useSelector((state: RootState) => state.initPayJP)

  const validateForm = () => {
    const isValid =
      document.querySelectorAll('.PayjpElement--complete').length === 3
    setIsFormValid(isValid)
    return isValid
  }

  const handleValidation = useCallback(
    (event: any, element: HTMLDivElement | null, pathName: string) => {
      if (!element) return

      if (event?.empty) {
        element.innerHTML = setFieldLang(
          textJP.validation_message.required,
          pathName
        )
      } else if (event?.error) {
        const { code, message } = event.error
        const listCodeError = ['incomplete_error', 'invalid_number']
        element.innerHTML = listCodeError.includes(code)
          ? textJP.validation_message.wrong_format
          : message
      } else {
        element.innerHTML = ''
      }
      validateForm()
    },
    []
  )

  const handleValidationCardName = (value: string) => {
    if (nameCard.current) {
      if (value.trim()) {
        nameCard.current.innerHTML = ''
      } else {
        const pathName = textJP.payment_info.card_name
        nameCard.current.innerHTML = setFieldLang(
          textJP.validation_message.required,
          pathName
        )
      }
    }
  }

  const onCardNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value = '' } = event.target || {}
    validateForm()
    if (!value || hiraganaAndAlphabetRegex.test(value)) {
      handleValidationCardName(value)
      setCardName(value)
    } else {
      handleValidationCardName(cardName)
    }
  }

  useEffect(() => {
    const initializeElements = () => {
      const payJPElements = window.Payjp.elements()
      const number = payJPElements.create('cardNumber', {
        style: payjpStyleInput,
        placeholder: '1234 5678 9012 3456'
      })
      const cvc = payJPElements.create('cardCvc', {
        style: payjpStyleInput,
        placeholder: ''
      })
      const expiry = payJPElements.create('cardExpiry', {
        style: payjpStyleInput,
        placeholder: '01/25'
      })
      number.mount('#card-number')
      cvc.mount('#card-cvc')
      expiry.mount('#card-expiry')
      const all = {
        number,
        cvc,
        expiry
      }
      setElements(all)
    }

    if (initPayJP) {
      initializeElements()
    }
  }, [initPayJP])

  useEffect(() => {
    if (elements) {
      elements.number.on('change', function (event: any) {
        handleValidation(
          event,
          cardNumber.current,
          textJP.payment_info.card_number
        )
      })

      elements.expiry.on('change', function (event: any) {
        handleValidation(
          event,
          cardExpiry.current,
          textJP.payment_info.expiry_date
        )
      })

      elements.cvc.on('change', function (event: any) {
        handleValidation(event, cardCvc.current, textJP.payment_info.cvc)
      })
    }
  }, [elements, handleValidation])

  const getToken = async () => {
    try {
      setErrorMess('')
      dispatch(setBlock())
      const response = await window.Payjp.createToken(elements.number, {
        card: { name: cardName }
      })
      if (response.error) {
        const message = response.error.code
        setErrorMess(message)
      } else {
        // if already had payment info then update, otherwise create new info
        const payload = {
          token: response.id
        }
        const res = await apiCreateOrUpdatePaymentMethod(payload, paymentInfo)
        if (res?.status === RESPONSE_STATUS.SUCCESS) {
          // dispatch save new info
          const {
            last4 = '',
            exp_month = '',
            exp_year = '',
            name = '',
            brand = ''
          } = res?.data?.data || {}
          const expiry_year = exp_year.toString().slice(-2) || ''
          const payload: PaymentInfoValues = {
            last4,
            exp_month,
            exp_year: expiry_year,
            name,
            brand
          }
          localStorage.setItem(
            LOCAL_STORAGE_KEYS.PAYMENT_INFO,
            JSON.stringify(payload)
          )
          handleNavigateApp({
            pathMobile: path.my_page,
            pathBrowser: path.home,
            navigate
          })
        }
      }
    } finally {
      dispatch(clearBlock())
    }
  }

  return (
    <LayoutMain
      title={textJP.payment_info.title}
      wrapperClassName='ly_container'
    >
      <>
        <div className='bl_flex justify_center mb-3'>
          <img src={listCredit} alt='' className='h-auto' />
        </div>

        <div className='el_txt_right ut_mt10'>
          <p className='el_txt_red el_txt_bold400'>
            {textJP.common.notice_required}
          </p>
        </div>

        <div className='bl_form ut_mt14'>
          <div className='bl_form_body'>
            <div className='bl_form'>
              <div className='bl_flex align_center'>
                <label htmlFor='card-number' className='required'>
                  {textJP.payment_info.card_number}
                </label>
                <p className='el_txt_s ml-6'>
                  {textJP.payment_info.card_number_notice}
                </p>
              </div>
              <div id='card-number' className='el_input h-30px py-0' />
              <div ref={cardNumber} className='el_txt_xs el_txt_red'></div>
            </div>
          </div>

          <div className='bl_form_body'>
            <div className='bl_form'>
              <label htmlFor='card-cvc' className='required'>
                {textJP.payment_info.cvc}
              </label>
              <p className='el_txt_s'>{textJP.payment_info.cvc_notice}</p>
              <div id='card-cvc' className='el_input h-30px py-0' />
              <div ref={cardCvc} className='el_txt_xs el_txt_red'></div>
            </div>
          </div>

          <div className='bl_form_body'>
            <div className='bl_form'>
              <label htmlFor='card-expiry' className='required'>
                {textJP.payment_info.expiry_date}
              </label>
              <div id='card-expiry' className='el_input h-30px py-0' />
              <div ref={cardExpiry} className='el_txt_xs el_txt_red'></div>
            </div>
          </div>

          <div className='bl_form_body'>
            <div className='bl_form'>
              <label htmlFor='card-name' className='required'>
                {textJP.payment_info.card_name}
              </label>
              <input
                maxLength={MAX_LENGTH_TEXT}
                value={cardName}
                onChange={event => onCardNumberChange(event)}
                onBlur={event => handleValidationCardName(event.target.value)}
                placeholder={textJP.payment_info.card_name_placeholder}
                id='card-name'
                className='el_input'
              />
              <div ref={nameCard} className='el_txt_xs el_txt_red'></div>
            </div>
          </div>
        </div>

        <div className='el_txt_center ut_mt20'>
          <ErrorResponse errorMess={errorMess} />
          <Button
            type='button'
            disabled={!isFormValid || !cardName}
            onClick={getToken}
            variant='contained'
            className='el_btn_common'
          >
            {textJP.btn.save}
          </Button>
        </div>
      </>
    </LayoutMain>
  )
}

export default PaymentInformationUpdate
