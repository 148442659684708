import { APPLICATION_ERROR_TYPE } from 'src/constants/enum'
import textJP from 'src/langs/ja'
import LayoutMain from 'src/layouts/LayoutMain'

type NotFoundProps = {
  type?: APPLICATION_ERROR_TYPE
}

export default function NotFound(props: NotFoundProps) {
  const { type } = props

  const getTitle = () => {
    switch (type) {
      case undefined:
      case APPLICATION_ERROR_TYPE.NOT_FOUND:
      case APPLICATION_ERROR_TYPE.NETWORK_ERROR:
      case APPLICATION_ERROR_TYPE.SYSTEM_ERROR:
        return textJP.common.error.system_error_title
      default:
        return ''
    }
  }

  const getMessage = () => {
    switch (type) {
      case undefined:
      case APPLICATION_ERROR_TYPE.NOT_FOUND:
      case APPLICATION_ERROR_TYPE.SYSTEM_ERROR:
      case APPLICATION_ERROR_TYPE.NETWORK_ERROR:
        return textJP.common.error.system_error_mess
      default:
        return ''
    }
  }

  return (
    <LayoutMain title={getTitle()} wrapperClassName='ly_container'>
      <>
        <span
          className='el_txt_center ut_mt50'
          dangerouslySetInnerHTML={{
            __html: getMessage()
          }}
        ></span>
        <div className='ut_mt50 el_txt_center'>
          <button
            type='button'
            onClick={() => window.location.reload()}
            className='el_btn_border el_btn_incIcon'
          >
            <span className='el_icm_load el_txt_xlg'></span>
            {textJP.btn.reload}
          </button>
        </div>
      </>
    </LayoutMain>
  )
}
