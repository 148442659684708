// uP21
import { Form, Formik } from 'formik'
import { useState } from 'react'
import FormItems from 'src/components/FormItems'
import LayoutMain from 'src/layouts/LayoutMain'
import Button from '@mui/material/Button'
import textJP from 'src/langs/ja'
import { getChangeEmailInitialValues } from 'src/helpers/initialValues'
import { changeEmailValidationSchema } from 'src/helpers/schema'
import ErrorResponse from 'src/components/ErrorResponse'
import { ChangeEmailValues } from 'src/models'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { clearBlock, setBlock } from 'src/stores/blockUI'
import path from 'src/routers/Path'
import { RESPONSE_STATUS } from 'src/constants/enum'
import { apiChangeEmail } from 'src/services/UserService'
import { RootState } from 'src/stores'

const ChangeEmail = () => {
  const [errorMess, setErrorMess] = useState<string>('')
  const email = useSelector((state: RootState) => state.profile.email)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const submit = async (values: ChangeEmailValues) => {
    try {
      setErrorMess('')
      dispatch(setBlock())
      const payload: ChangeEmailValues = {
        email: values.current_email,
        email_temp: values.email_temp
      }
      const response = await apiChangeEmail(payload)
      if (response?.status === RESPONSE_STATUS.SUCCESS) {
        navigate(path.change_email_verify)
      }
    } catch (error: any) {
      const message = error?.response?.data?.message
      setErrorMess(message)
    } finally {
      dispatch(clearBlock())
    }
  }

  return (
    <LayoutMain
      title={textJP.change_email.title}
      wrapperClassName='ly_container'
    >
      <Formik
        initialValues={getChangeEmailInitialValues(email as string)}
        validationSchema={changeEmailValidationSchema}
        onSubmit={submit}
      >
        {() => {
          return (
            <Form>
              <div className='bl_form'>
                <FormItems
                  name='current_email'
                  label={textJP.common.current_email}
                  placeholder={textJP.common.current_email}
                  inputMode='email'
                />

                <FormItems
                  name='email_temp'
                  label={textJP.common.email_temp}
                  placeholder={textJP.common.email_temp}
                  inputMode='email'
                />
              </div>

              <p className='el_txt_s'>{textJP.change_email.notice}</p>

              <div className='el_txt_center ut_mt20'>
                <ErrorResponse errorMess={errorMess} />
                <Button
                  type='submit'
                  variant='contained'
                  className='el_btn_common'
                >
                  {textJP.btn.save}
                </Button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </LayoutMain>
  )
}

export default ChangeEmail
